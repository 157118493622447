@import url("https://fonts.googleapis.com/css?family=Muli:400,500,600,700&display=swap");

:root {
  --primario: #511e84;
  --secundario: #ff7d54;
  --terciario: #a87fd2;
  --texto: #2c2633;
  --texto-boton: #ffffff;
  --fondo: #eeeeee;
  --gris: #d6d6d6;
  --grisclaro: #cccccc;
  --barra: #ffffff;
  --black: rgba(0, 0, 0, .85);
  --width-nla: 80px;
  --error-color: #ff3b3b;
  --success-color: #5cb85c;
  --warn-color: #f0ad4e;
  --info-color: #5bc0de;
  --background-rgba: rgba(255, 255, 255, .8);
  --dark: #0b0a09;

  /* chat */
  --fondo-emisor: #8F8F8F;
  --fondo-receptor: #e2e2e2;
  --text-emisor: #ffffff;
  --text-receptor: #2c2633;
  --calendar-color: invert(0);

  --mdc-theme-primary: var(--primario);
  --mdc-typography-body1-font-family: "Muli", sans-serif;
  --mdc-chip-container-height: 29px;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-color: var(--barra);
  --mdc-dialog-subhead-color: var(--texto);
  --mdc-dialog-supporting-text-color: var(--texto);
}

.mat-mdc-dialog-container {
  --mdc-dialog-subhead-font: "Muli", sans-serif;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: "Muli", sans-serif;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 16px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0.03125em;
}

.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-focus-icon-color: var(--primario);
  --mdc-checkbox-selected-hover-icon-color: var(--primario);
  --mdc-checkbox-selected-icon-color: var(--primario);
  --mdc-checkbox-selected-pressed-icon-color: var(--primario);
}

.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: var(--primario) !important;
  --mdc-switch-selected-handle-color: var(--primario) !important;
  --mdc-switch-selected-hover-state-layer-color: var(--primario) !important;
  --mdc-switch-selected-pressed-state-layer-color: var(--primario) !important;
  --mdc-switch-selected-focus-handle-color: var(--primario) !important;
  --mdc-switch-selected-hover-handle-color: var(--primario) !important;
  --mdc-switch-selected-pressed-handle-color: var(--primario) !important;
  --mdc-switch-selected-focus-track-color: var(--terciario) !important;
  --mdc-switch-selected-hover-track-color: var(--terciario) !important;
  --mdc-switch-selected-pressed-track-color: var(--terciario) !important;
  --mdc-switch-selected-track-color: var(--terciario) !important;
}

.mat-mdc-radio-button {
  --mdc-radio-selected-focus-icon-color: var(--primario) !important;
  --mdc-radio-selected-hover-icon-color: var(--primario) !important;
  --mdc-radio-selected-icon-color: var(--primario) !important;
  --mdc-radio-selected-pressed-icon-color: var(--primario) !important;
  --mat-mdc-radio-checked-ripple-color: var(--primario) !important;
}

.mat-mdc-radio-button .mdc-form-field>label {
  margin: 0 !important;
  cursor: pointer !important;
}

.mat-mdc-radio-button .mdc-form-field,
.mat-mdc-checkbox .mdc-form-field {
  margin: .35rem .25rem 0 !important;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon,
.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__icon {
  display: none !important;
}

.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected~.mdc-checkbox__ripple {
  background: transparent !important;
}

.mat-mdc-slide-toggle .mdc-form-field>label {
  margin: 0 !important;
  margin-left: .35rem !important;
  cursor: pointer !important;
}

.fr {
  float: right;
}

.fl {
  float: right;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

input[type=number] {
  appearance: textfield !important;
}

html,
body {
  font-family: "Muli", sans-serif;
  color: var(--texto);
  margin: 0;
  padding: 0;
  font-size: 16px;
}

a {
  color: var(--primario);
  text-decoration: underline;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
}

.labelFilter {
  user-select: none !important;
}

.labelFilter.disabled {
  cursor: not-allowed !important;
  background: var(--gris) !important;
}

.fa,
.fas {
  padding-right: 5px;
}

.mdl-button {
  color: var(--primario);
  font-family: "Muli", sans-serif;
  font-size: 1rem;
  text-transform: None;
  border-radius: 100px;
  padding: 0 1rem;
}

.mdl-button2 {
  color: var(--secundario);
  font-family: "Muli", sans-serif;
  font-size: 1rem;
  text-transform: None;
  border-radius: 10px;
  padding: 0 0.5rem;
  background-color: white !important;
  border: 1px solid var(--secundario);
}

.mdl-layout__header,
.mdl-layout__drawer,
.mdl-layout__obfuscator {
  position: fixed;
  top: 0;
}

h3 {
  font-size: 25px;
  font-weight: 700;
  color: var(--texto);
  margin: 2px 0px 2px 0px;
  line-height: 30px;
}

h3 a {
  font-size: 25px;
  font-weight: 700;
}

h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 10px 0;
}

.boton-modal:hover {
  color: white !important;
  background-color: var(--secundario) !important;
}

.boton-modal:active {
  color: white !important;
  background-color: var(--secundario) !important;
}

.boton-modal:visited {
  color: white !important;
  background-color: var(--secundario) !important;
}

.boton-modal:focus {
  color: white !important;
  background-color: var(--secundario) !important;
}

.boton-modal:focus:not(.active) {
  color: white !important;
  background-color: var(--secundario) !important;
}

.mat-mdc-menu-item {
  font-size: 1rem;
  font-weight: 400;
}

.mdl-color--grey-100 {
  background-color: var(--fondo) !important;
}

.mdl-demo .mdl-layout.is-small-screen .mdl-layout__header-row h3 {
  font-size: inherit;
}

.mdl-demo .mdl-layout__tab-bar-button {
  display: none;
}

.mdl-demo .mdl-layout.is-small-screen .mdl-layout__tab-bar .mdl-button {
  display: none;
}

.mdl-demo .mdl-layout:not(.is-small-screen) .mdl-layout__tab-bar,
.mdl-demo .mdl-layout:not(.is-small-screen) .mdl-layout__tab-bar-container {
  overflow: visible;
}

.mdl-demo .mdl-layout__tab-bar-container {
  height: 64px;
}

.mdl-demo .mdl-layout__tab-bar {
  padding: 0;
  padding-left: 16px;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

.mdl-demo .mdl-layout__tab-bar .mdl-layout__tab {
  height: 64px;
  line-height: 64px;
}

.mdl-demo .mdl-layout__tab-bar .mdl-layout__tab.is-active::after {
  background-color: white;
  height: 4px;
}

.mdl-demo main>.mdl-layout__tab-panel {
  padding: 8px;
  padding-top: 48px;
}

.mdl-demo .mdl-card {
  height: auto;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 5px;
}

.mdl-demo .mdl-card>* {
  height: auto;
}

.mdl-demo .mdl-card .mdl-card__supporting-text {
  flex-grow: 1;
  padding: 0;
  color: inherit;
  text-align: center;
  margin: 10px 10px 10px 20px;
}

.mdl-demo.mdl-demo .mdl-card__supporting-text h4 {
  margin-top: 0;
  margin-bottom: 20px;
}

.mdl-demo .mdl-card__actions {
  margin: 0;
  padding: 4px 40px;
  color: inherit;
}

.mdl-demo .mdl-card__actions a {
  color: #1d76d0;
  margin: 0;
}

.mdl-demo .mdl-card__actions a:hover,
.mdl-demo .mdl-card__actions a:active {
  color: inherit;
  background-color: transparent;
}

.mdl-demo .mdl-card__supporting-text+.mdl-card__actions {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.mdl-demo #add {
  position: absolute;
  right: 40px;
  top: 36px;
  z-index: 999;
}

.mdl-demo .mdl-layout__content section:not(:last-of-type) {
  position: relative;
  margin-bottom: 48px;
}

.mdl-demo section.section--center {
  max-width: 860px;
}

.mdl-demo #features section.section--center {
  max-width: 620px;
}

.mdl-demo section>header {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.mdl-demo section>.section__play-btn {
  min-height: 200px;
}

.mdl-demo section>header>.material-icons {
  font-size: 3rem;
}

.mdl-demo section>button {
  position: absolute;
  z-index: 99;
  top: 8px;
  right: 8px;
}

.mdl-demo section .section__circle {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
}

.mdl-demo section .section__text {
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-top: 8px;
}

.mdl-demo section .section__text h5 {
  font-size: inherit;
  margin: 0;
  margin-bottom: 0.5em;
}

.mdl-demo section .section__text a {
  text-decoration: none;
}

.mdl-demo section .section__circle-container>.section__circle-container__circle {
  width: 64px;
  height: 64px;
  border-radius: 32px;
  margin: 8px 0;
}

.mdl-demo section.section--footer .section__circle--big {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  margin: 8px 32px;
}

.mdl-demo .is-small-screen section.section--footer .section__circle--big {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin: 8px 16px;
}

.mdl-demo section.section--footer {
  padding: 64px 0;
  margin: 0 -8px -8px -8px;
}

.mdl-demo section.section--center .section__text:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}

.mdl-demo .mdl-card .mdl-card__supporting-text>h3:first-child {
  margin-bottom: 24px;
}

.mdl-demo .mdl-layout__tab-panel:not(#overview) {
  background-color: white;
}

.mdl-demo #features section {
  margin-bottom: 72px;
}

.mdl-demo #features h4,
#features h5 {
  margin-bottom: 16px;
}

.mdl-demo .toc {
  border-left: 4px solid #c1eef4;
  margin: 24px;
  padding: 0;
  padding-left: 8px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.mdl-demo .toc h4 {
  font-size: 0.9rem;
  margin-top: 0;
}

.mdl-demo .toc a {
  color: #1d76d0;
  text-decoration: none;
  font-size: 16px;
  line-height: 28px;
  display: block;
}

.mdl-demo .mdl-menu__container {
  z-index: 99;
}

h3.title {
  color: #1d76d0 !important;
}

p {
  font-size: 16px;
  color: var(--texto);
}

.mdl-chip__text {
  font-size: 10px;
  color: #fff;
}

.mdl-chip {
  height: 19px;
  line-height: 20px;
  padding: 0 5px;
}

.mdl-color--primary,
.mdl-layout__header {
  background-color: var(--barra);
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.08), 0 1px 10px 0 rgba(0, 0, 0, 0.06),
    0 2px 4px -1px rgba(0, 0, 0, 0.1);
}

.green-ca {
  color: #009999;
}

.mdl-button--accent.mdl-button--accent.mdl-button--fab:disabled,
.mdl-button--accent.mdl-button--accent.mdl-button--raised:disabled {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
}

.mdl-checkbox.is-checked .mdl-checkbox__tick-outline {
  background: #009999;
}

.mdl-checkbox.is-checked .mdl-checkbox__box-outline {
  border: 2px solid #009999;
}

.text-black {
  color: #000000;
}

.text-white {
  color: #ffffff;
}

.text-red {
  color: red;
}

.text-green {
  color: var(--primario);
}

.background-yellow {
  background-color: rgb(255, 235, 59);
}

.background-light-blue {
  background-color: rgb(3, 169, 244);
}

.background-purple {
  background-color: rgb(103, 58, 183);
}

.background-orange {
  background-color: rgb(255, 152, 0);
}

.background-lime {
  background-color: rgb(139, 195, 74);
}

.mdl-layout__obfuscator.is-visible {
  opacity: 0.5;
}

.mdl-button--accent-light {
  color: #009999;
  background-color: #fff;
  border: 1px solid #cccccc;
}

.mdl-button--accent-light:hover {
  background-color: rgba(255, 255, 255, 0.67);
}

.mdl-button--blue {
  color: #1d76d0;
  border-color: #1d76d0;
  border: 1px solid #1d76d0;
}

.mdl-button--pac {
  color: #1d76d0;
  margin: 0px 7px;
  border-color: #1d76d0;
  border: 1px solid #1d76d0;
}

.mdl-button--pac:hover {
  background: rgba(158, 158, 158, 0.2) !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
}

.mdl-button--pac .mat-mdc-badge-content {
  background: #1d76d0 !important;
}

.mdl-button--blue:hover {
  background-color: #1d76d0;
  color: #fff;
  box-shadow: unset !important;
}

.mdl-button .mdl-button--blue:hover {
  box-shadow: none;
}

.dataTables_filter input[type="search"] {
  padding: 10px;
  padding: 1rem;
  border: 1px solid #f1f1f1;
  width: 73%;
}

.dataTables_length label {
  font-size: 1rem !important;
}

table.dataTable thead>tr>th {
  text-align: left;
  border: solid 1px rgba(0, 0, 0, 0.1);
}

table.dataTable tbody>tr>td {
  text-align: left;
  border: solid 1px rgba(0, 0, 0, 0.1);
}

.datos-grupo {
  border: solid 1px rgba(0, 0, 0, 0.1) !important;
}

.menu-right {
  float: right;
  display: inline-flex;
}

table.dataTable tbody>tr>td.menu span {
  margin-right: 30px;
}

#table-menu-principal {
  display: block;
  position: fixed;
  right: 0;
  bottom: 0;
  margin-right: 100px;
  margin-bottom: 50px;
  z-index: 900;
}

div.dataTables_wrapper div.dataTables_filter {
  text-align: left;
}

div.dataTables_length {
  text-align: right;
}

div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0px;
}

.myfilter {
  margin-left: -5px;
}

table.dataTable div.menu {
  width: 100%;
  margin-right: 32px;
}

table.dataTable div.menu span {
  display: inline-block;
  padding-top: 5px;
}

table.dataTable div.menu button {
  float: right;
}

div.dataTables_wrapper div.mdl-grid.dt-table {
  width: 100%;
}

.menu a {
  color: var(--texto);
  font-size: 13px;
}

.mdl-button--raised.mdl-button--colored:active,
.mdl-button--raised.mdl-button--colored:hover {
  background-color: var(--primario);
}

table td.wrap-50 {
  white-space: normal !important;
  width: 50px !important;
  max-width: 50px !important;
  word-wrap: break-word !important;
}

table td.wrap-100 {
  white-space: normal !important;
  width: 100px !important;
  max-width: 100px !important;
  word-wrap: break-word !important;
}

table td.wrap-200 {
  white-space: normal !important;
  width: 200px !important;
  max-width: 200px !important;
  word-wrap: break-word !important;
}

table td.wrap-txt {
  white-space: normal !important;
}

table td.wrap-400 {
  width: 400;
}

div.dataTables_wrapper div.dataTables_info {
  white-space: normal !important;
  font-size: 1rem;
}

table.dataTable.dtr-column>tbody>tr>td.control:before,
table.dataTable.dtr-column>tbody>tr>th.control:before {
  top: 50%;
  left: 50%;
  height: 22px;
  width: 22px;
  margin-top: -10px;
  margin-left: -10px;
  display: block;
  position: absolute;
  color: white;
  border: none;
  border-radius: 14px;
  box-shadow: none;
  box-sizing: content-box;
  text-align: center;
  text-indent: 0 !important;
  line-height: 24px;
  font-size: 12px;
  font-weight: 900;
  background-color: var(--primario);
}

table.dataTable.dtr-column>tbody>tr.parent td.control:before,
table.dataTable.dtr-column>tbody>tr.parent th.control:before {
  font-weight: 900;
  background-color: var(--primario);
}

table.dataTable .mdl-menu__item {
  height: 30px;
  line-height: 30px;
  font-size: 13px;
}

.mdl-button--raised.mdl-button--colored {
  background: var(--primario);
  color: #fff;
}

.menu-table {
  text-align: right;
}

.download-button {
  margin: 5px;
}

.mdl-layout__drawer {
  background-color: #fff;
  width: 249px;
  border: none;
  box-shadow: none;
}

span.arrow {
  float: right;
}

.mdl-layout--fixed-drawer.is-upgraded:not(.is-small-screen)>.mdl-layout__header {
  margin-left: 210px;
  width: calc(100% - 210px);
}

nav.mdl-navigation a.mdl-navigation__link i.material-icons {
  color: #1d76d0;
  margin-right: 6px;
  line-height: 18px;
}

nav.mdl-navigation a.mdl-navigation__link {
  color: var(--texto) !important;
  overflow: hidden;
  height: 40px;
}

nav.mdl-navigation ul.mdl-list li.mdl-list__item a {
  color: #646464 !important;
}

nav.mdl-navigation ul.mdl-list li.mdl-list__item i.material-icons {
  color: #646464 !important;
}

nav.mdl-navigation hr {
  margin: 0.5rem 1rem;
}

nav.mdl-navigation ul {
  margin-top: 0px;
  margin-bottom: 0px;
}

.nav-profile {
  display: flex;
  box-sizing: border-box;
  margin-right: 30px;
}

.nav-profile label {
  margin-right: 10px;
  background-color: #fff;
}

.nav-profile label i {
  color: #1d76d0;
}

.nav-profile .mdl-button--icon {
  height: 38px;
  width: 38px;
  background: var(--fondo);
  padding: 0;
  border-radius: 50%;
  overflow: hidden;
}

.nav-profile .mdl-button:hover {
  background-color: #eee;
}

.nav-profile .calificativo {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0px;
  height: 18px;
}

.botonera {
  margin-top: -40px;
}

.mdl-layout--fixed-drawer>.mdl-layout__content {
  margin-left: 210px;
  padding-bottom: 60px;
}

.grupo-card-square {
  width: 100%;
}

.mdl-card__title-text {
  color: #696969;
  font-weight: 600;
  font-size: 18px;
}

.mdl-card__title {
  border-bottom: 1px solid #ececf1;
}

div.item {
  width: 100%;
  display: flex;
  padding: 2px 10px 0px 10px;
}

div.item span:first-child {
  width: 65%;
}

div.item span:nth-child(2) {
  padding-left: 8px;
}

div.item span {
  font-size: 12px;
  color: #696969;
}

div.foot {
  font-size: 1rem;
  margin: 10px 0px 10px 0px;
}

div.foot span {
  color: var(--texto);
}

.grupo-card-square .mdl-card__title {
  padding: 5px 0px 0px 0px;
}

.grupo-card-square .mdl-grid {
  margin: 0px 5px 0px 5px;
  width: 100%;
}

.btn-group-export {
  float: right;
}

nav hr {
  border-top: 1px solid #f3f1f1;
}

.mdl-chip1__text1 {
  font-size: 10px;
}

.mdl-chip1 {
  height: 19px;
  line-height: 20px;
  padding: 0 5px;
  background-color: #91d3d36b !important;
  color: var(--texto);
  border-radius: 8px;
}

.mdl-button:hover {
  box-shadow: none !important;
}

.mdl-fab-bottom-right {
  position: fixed;
  bottom: 24px;
  right: 24px;
  transition: bottom 0.25s cubic-bezier(0, 0, 0.2, 1);
}

.mdl-button--fab-expandable,
.mdl-button--fab-expandable * {
  z-index: 9999;
}

.mdl-button--fab-expandable.is-active>.mdl-fab-expandable--children {
  display: block !important;
}

.mdl-button--fab-expandable.bottom>.mdl-fab-expandable--children {
  display: none;
  position: absolute;
  bottom: 56px;
  left: 8px;
}

.mdl-button--fab-expandable.top>.mdl-fab-expandable--children {
  display: none;
  position: absolute;
  top: 56px;
  left: 8px;
}

.mdl-button--fab-expandable.bottom>.mdl-fab-expandable--children-small {
  bottom: -75px;
}

.mdl-button--fab-expandable.bottom .mdl-fab-expandable--child {
  position: relative;
  margin-bottom: 16px;
}

.mdl-button--fab-expandable.top .mdl-fab-expandable--child {
  position: relative;
  margin-top: 16px;
}

.mdl-fab-expandable--children .mdl-fab-expandable--child-label {
  min-width: 150px;
}

.mdl-fab-expandable--child-label>* {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  background-color: #fff;
  padding: 8px;
  border-radius: 8px;
}

.mdl-button--fab-expandable.left .mdl-fab-expandable--child-label {
  position: absolute;
  left: 48px;
  top: 10px;
  text-align: left;
}

.mdl-button--fab-expandable.right .mdl-fab-expandable--child-label {
  position: absolute;
  right: 48px;
  top: 10px;
  text-align: right;
}

.mdl-button--fab-expandable.right .mdl-fab-expandable--child-menu {
  position: absolute;
  right: -26px;
  top: -194px;
  min-width: 150px;
  background-color: #fff;
}

.mdl-fab-expandable--child-menu ul {
  padding-left: 0px;
  padding-bottom: 3px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.mdl-fab-expandable--child-menu {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  padding-left: 0px;
}

.mdl-button--fab-expandable li {
  list-style: none;
}

.mdl-fab-expandable--child-menu .item:hover {
  background-color: #eee;
}

.mdl-fab-expandable--child-menu .item {
  display: block;
  border: none;
  color: rgba(0, 0, 0, 0.87);
  text-align: left;
  margin: 0;
  padding: 0 16px 0px 26px;
  outline-color: #bdbdbd;
  position: relative;
  overflow: hidden;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0;
  text-decoration: none;
  cursor: pointer;
  height: 35px;
  line-height: 38px;
  white-space: nowrap;
  opacity: 1;
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mdl-fab-expandable--child-menu .item a {
  text-decoration: none;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.87);
}

.child-menu-list {
  right: 120px !important;
  top: -248px !important;
  padding: 0;
  position: absolute;
  border-radius: 5px;
  width: 150px;
  display: none;
  opacity: 0;
  visibility: hidden;
  -webkit-transiton: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -ms-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.child-menu-list li {
  cursor: pointer;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.child-menu-list li:hover ul {
  display: block;
  opacity: 1;
  visibility: visible;
}

.child-menu-hover {
  display: block;
  opacity: 1;
  visibility: visible;
}

.child-menu .material-icons {
  vertical-align: middle;
}

.menu-hitos {
  margin-left: -26px;
}

.menu-hitos i {
  color: #616161;
}

.child-menu-list hr {
  margin: 4px 0px 2px 15px;
  border-top: 1px solid #f3f1f1;
}

.bubble {
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 16px;
  margin-right: 4px;
}

table div.menu .bubble {
  width: 9px;
  height: 4px;
  display: inline-block;
  border-radius: 8px;
  margin-right: 4px;
}

table div.menu .mdl-fab-expandable--child-menu .item {
  display: block;
  border: none;
  color: rgba(0, 0, 0, 0.87);
  text-align: left;
  margin: 0;
  padding: 0 16px 0px 12px;
  outline-color: #bdbdbd;
  position: relative;
  overflow: hidden;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  text-decoration: none;
  cursor: pointer;
  height: 26px;
  line-height: 26px;
  white-space: nowrap;
  opacity: 1;
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

div.menu .child-menu-list {
  right: -150px !important;
  top: 44px !important;
  z-index: 99999999999999999999;
  background-color: #fff;
}

.child-menu span {
  color: rgba(0, 0, 0, 0.87);
}

.content-select2-multiple2-filter {
  float: right;
}

.mdl-dialog {
  border: none;
  box-shadow: 0 9px 46px 8px rgba(0, 0, 0, 0.14),
    0 11px 15px -7px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.2);
  width: 280px;
}

.mdl-dialog__title {
  padding: 12px 0px !important;
  margin: 0 !important;
  font-family: "Muli", sans-serif;
  font-size: 21px;
  font-weight: 600;
  color: var(--primario);
}

.mdl-dialog__actions {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.mdl-dialog__actions>* {
  margin-right: 8px;
  height: 36px;
}

.mdl-dialog__actions--full-width>* {
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  padding-right: 16px;
  margin-right: 0;
  text-align: right;
}

.mdl-dialog__content {
  padding: 20px 24px 24px 24px;
  color: rgba(0, 0, 0, 0.54);
}

.mdl-dialog .mdl-button:hover,
.mdl-dialog .mdl-button:focus:not(:active),
.modal .mdl-button:hover,
.modal .mdl-button:focus:not(:active) {
  background-color: var(--primario);
  color: #fff;
}

.mdl-dialog .mdl-button:hover:disabled,
.mdl-dialog .mdl-button:focus:not(:active),
.modal .mdl-button:hover:disabled,
.modal .mdl-button:focus:not(:active) {
  color: var(--primario);
  cursor: default;
  background-color: white;
}

.mdl-dialog .mdl-button-cancel:hover,
.modal .mdl-button-cancel:hover {
  background-color: white;
  color: var(--primario);
}

.mdl-dialog .mdl-button,
.modal .mdl-button {
  color: var(--primario);
}

.mdl-dialog-med {
  width: 500px;
}

.mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
.mdl-textfield--floating-label.is-focused .mdl-textfield__label {
  color: var(--primario);
}

.getmdl-select.is-focused .mdl-icon-toggle__label {
  color: var(--primario);
  transform: rotate(180deg);
}

.mdl-textfield__label:after {
  color: var(--primario);
}

.mdl-button--sm {
  height: 24px;
  line-height: 24px;
  font-size: 11px;
}

.mdl-button--sm .material-icons i {
  font-size: 15px;
}

span.title-text {
  color: var(--texto);
  font-weight: 600;
  font-size: 18px;
}

.icon-export:hover {
  -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: rgba(158, 158, 158, 0.2);
}

.icon-export {
  border-radius: 50%;
  font-size: 24px;
  height: 32px;
  margin-left: 0;
  margin-right: 0;
  min-width: 32px;
  width: 32px;
  padding: 0;
  overflow: hidden;
  color: inherit;
  line-height: normal;
  float: right;
}

.icon-export img {
  line-height: 24px;
  width: 24px;
  padding: 5px;
}

form.grupos-search {
  background-color: #fff;
  padding-right: 20px;
  padding-left: 10px;
  margin-right: 20px;
}

form.grupos-search button.search {
  float: right;
  width: 85%;
  margin-top: 13px;
}

.card-filter {
  min-height: 100px;
}

form.search button {
  margin-left: 10px;
  margin-top: -5px;
}

.item-card.mdl-card {
  min-height: 80px;
  width: 100%;
}

.item-card>.mdl-card__actions {
  border-color: rgba(255, 255, 255, 0.2);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.item-card>.mdl-card__title {
  align-items: flex-start;
}

.item-card>.mdl-card__title>h4 {
  margin-top: 0;
}

.item-card>.mdl-card__actions>.material-icons {
  padding-right: 10px;
}

.item-card>.mdl-card__title,
.item-card>.contenido,
.item-card>.contenido>.mdl-button {
  color: #d6d6d6;
}

.item-card>.contenido>i {
  color: #d6d6d6;
}

.item-card>.contenido h5 {
  margin-top: 3px;
}

.item-card>.contenido .color {
  color: #009999;
}

.item-card>.contenido .color-light {
  color: #d6d6d6;
}

.item-card .material-icons.med {
  font-size: 48px;
}

.item-card span {
  color: #606060;
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase;
}

.item-card .mdl-grid {
  margin: 0;
}

button.delete {
  background-color: #606060;
}

.mdl-switch__ripple-container .mdl-ripple {
  background: #009999;
}

.mdl-switch.is-checked .mdl-switch__thumb {
  background: #009999;
  left: 16px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
}

.mdl-switch.is-checked .mdl-switch__track {
  background: rgba(63, 176, 181, 0.5);
}

.item-card .mdl-switch {
  display: inline;
}

.item-card .mdl-switch span {
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: #009999;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  float: left;
  margin-right: 27px;
  padding-top: 3px;
}

form.search {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  background-color: #fff;
}

form.filter {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  background-color: #fff;
}

form.filter button {
  margin-top: 10px;
}

button.search {
  background-color: #1d76d0 !important;
}

button.search:disabled {
  background-color: #dddddd !important;
}

button.primary {
  background-color: #1d76d0 !important;
}

button.primary:disabled {
  background-color: #dddddd !important;
}

form.search .filter {
  padding-left: 20px;
}

.getmdl-select .mdl-textfield__input {
  cursor: pointer;
  font-size: 13px;
}

.mdl-textfield__label {
  font-size: 13px;
}

.detalle-paciente .botonera {
  margin-top: 0px;
  float: right;
}

.detalle-paciente .botonera button {
  margin-right: 20px;
}

.icon-item {
  margin-right: 10px;
  float: left;
}

.note-actions {
  float: right;
}

span.note {
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.02em;
  text-transform: inherit;
}

.search .download-button {
  float: right;
  margin-top: 15px;
}

.mdl-switch span {
  padding-bottom: 15px;
}

.btn {
  background: 0 0;
  border: none;
  border-radius: 2px;
  color: #009999;
  position: relative;
  height: 36px;
  margin: 0;
  min-width: 64px;
  padding: 0 16px;
  display: inline-block;
  font-family: "Muli", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0;
  overflow: hidden;
  will-change: box-shadow;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  line-height: 36px;
  vertical-align: middle;
}

.dtp div.dtp-date,
.dtp div.dtp-time {
  background: #009999;
  text-align: center;
  color: #fff;
  padding: 10px;
}

.dtp>.dtp-content>.dtp-date-view>header.dtp-header {
  background: #009999;
  color: #fff;
  text-align: center;
  padding: 0.3em;
}

.dtp table.dtp-picker-days tr>td>a.selected {
  background: #009999;
  color: #fff;
}

.dataTables_scroll {
  background-color: #fff;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.content-select2-multiple2-filter .select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 8px;
}

.content-select2-multiple2-filter .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 42px;
}

.content-select2-multiple2-filter .select2-container .select2-selection--single {
  height: 42px;
}

.content-select2-multiple2-filter .select2-selection__clear {
  display: none;
}

.select2-results__option .wrap:before {
  color: #616161 !important;
  width: 25px;
  height: 25px;
  padding-right: 10px;
  font-family: "Material Icons", sans-serif;
}

.select2-results__option[aria-selected="true"] .wrap:before {
  content: "\E834";
}

.select2-multiple,
.select2-multiple2 {
  width: 50%;
}

select .select2-multiple2 {
  width: 100% !important;
}

.select2-container--material {
  width: 100% !important;
}

.select2-container--material ::placeholder {
  color: inherit;
}

.select2-results__option .wrap {
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent;
  text-align: left;
  margin: 0;
  padding: 0 16px;
  outline-color: #bdbdbd;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid whitesmoke;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: none;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 2px;
  font-size: 13px;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: #0099992e;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: var(--gris);
  font-family: "Muli", sans-serif;
  margin: 0;
  padding: 4px 0;
  font-size: 13px;
  letter-spacing: 0.2px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #eee;
  color: #666666;
}

.select2-results__option {
  height: 42px;
  line-height: 42px;
  white-space: nowrap;
}

.select2-dropdown {
  background-color: white;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  z-index: 1051;
}

td div.detalle div.name {
  width: 100px;
  white-space: nowrap;
}

td div.detalle div.par:not(:first-of-type) {
  font-size: 11px;
}

td .detalle .prom {
  border-bottom: 0.1em solid #c6c6c6 !important;
  width: 100px !important;
  font-weight: 500 !important;
}

.par {
  padding: 1px 0px 0px 5px;
}

.parametrodetalles {
  width: 100%;
  text-align: left;
  color: #616161;
  font-weight: 500;
}

#show-detail span.close {
  float: right;
}

#monitoreo-general tr.odd {
  background-color: #e8e8e840 !important;
}

#monitoreo-general td .mdl-button {
  color: #616161 !important;
}

td div.detalle div.par div {
  display: inline-block;
}

.custom-header {
  margin: 10px 2px 10px -1px;
}

#modal-agregar-paciente div.contenido-modal {
  margin-top: -20px;
}

.custom-header span,
.custom-header-filter span {
  color: #009999;
  font-size: 1rem;
  font-weight: 500;
}

.custom-header-filter strong {
  padding-left: 15px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 16px;
}

.custom-header-filter {
  margin-left: -12px;
}

.filter-header strong {
  padding-left: 0px !important;
}

#ms-select-hito-list,
#ms-select-agregar-paciente {
  width: 100%;
}

.search-list {
  padding: 14px;
  border: 1px solid #f1f1f1;
  width: 90%;
  margin-top: 10px;
}

input[type="text"].search-list::-webkit-input-placeholder,
input[type="text"].search-list::-moz-placeholder,
input[type="text"].search-list::-ms-input-placeholder {
  color: var(--gris);
}

.search-list::placeholder {
  color: var(--gris);
}

#select-parametro-paciente {
  display: inline-block;
  margin-bottom: 0px;
  margin-top: -10px;
  margin-left: 6px;
}

.filter-header p {
  font-size: 11px;
}

#select-agregar-paciente .ms-container .ms-selectable {
  margin-top: -30px !important;
}

#ms-select-agregar-paciente .ms-list {
  margin-top: 20px !important;
}

.ms-container .ms-selectable li.ms-hover,
.ms-container .ms-selection li.ms-hover {
  cursor: pointer;
  color: #fff;
  text-decoration: none;
  background-color: #eee;
}

.ms-container .ms-list {
  border: none;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 2px;
}

.ms-container .ms-selectable li.ms-elem-selectable,
.ms-container .ms-selection li.ms-elem-selection {
  border-bottom: 1px #eee solid;
  padding: 6px 12px;
  color: #616161;
  font-size: 1rem;
}

.modal-xl .modal__content {
  width: 800px;
}

.modal-xl .modal__dialog {
  max-width: 800px;
}

.cdk-overlay-pane.modal-xlarge {
  max-width: 90vw !important;
}

.cdk-overlay-pane.modal-fullscreen {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
  min-height: 100% !important;
  max-height: 100% !important;
}

.cdk-overlay-pane.modal-fullscreen .mat-mdc-dialog-content {
  height: calc(100% - (58px + 54px));
  min-height: calc(100% - (58px + 54px));
  max-height: calc(100% - (58px + 54px));
  overflow-y: auto;
  overflow-x: hidden;
}

.modal__text {
  color: var(--texto);
  font-size: 1rem;
  line-height: 20px;
}

.btn-list {
  float: right;
}

.card-custom .icon-item,
.card-custom .content span,
.card-custom .content h5 {
  color: #fff !important;
}

.card-custom .material-icons {
  font-size: 60px;
}

.card-green-yellow {
  background-color: #e8c604;
}

.card-green {
  background-color: #09b024;
}

.card-principal {
  background-color: #009999;
}

.card-purple {
  background-color: #6104a6;
}

.card-pink {
  background-color: #e91e63;
}

.card-royalblue {
  background-color: #0e126b;
}

.grupo-card-square table.mdl-data-table thead tr {
  border: none;
  background-color: rgb(238, 238, 238);
}

.modal__footer button {
  float: right;
}

#modal-bloquear .mdl-menu__item {
  height: 28px;
  line-height: 20px;
}

.center {
  text-align: center;
}

.v-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.no-menu .mdl-layout--fixed-drawer>.mdl-layout__content {
  margin-left: 0px;
  padding: 20px 40px 20px 40px;
}

.no-menu .mdl-layout--fixed-drawer.is-upgraded:not(.is-small-screen)>.mdl-layout__header {
  margin-left: 0px;
  width: 100%;
}

.profile-mobile {
  display: none;
}

.info-profile {
  margin-bottom: 8px;
}

.nav-fostrap {
  display: block;
}

.nav-fostrap ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: block;
}

.nav-fostrap a.inline {
  display: inline-flex;
}

.nav-fostrap li {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline-block;
  position: relative;
  font-size: 12px;
  color: #fff;
}

.nav-fostrap li a {
  font-size: 1rem;
  color: #fff;
  display: inline-block;
  outline: 0;
  font-weight: 400;
}

.nav-fostrap li:hover ul.dropdown {
  display: block;
}

.nav-fostrap li ul.dropdown {
  position: absolute;
  display: none;
  width: 200px;
  margin-top: -4px;
  background: #fff;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  -ms-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  -o-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  padding-top: 0;
}

.nav-fostrap li ul.dropdown li {
  display: block;
  list-style-type: none;
}

.nav-fostrap li ul.dropdown li a {
  padding: 15px 20px;
  font-size: 12px;
  color: var(--texto);
  display: block;
  font-weight: 500;
}

.nav-fostrap li ul.dropdown li:last-child a {
  border-bottom: none;
}

.nav-fostrap li:hover a {
  color: #fff;
}

.nav-fostrap li:first-child:hover a {
  border-radius: 3px 0 0 3px;
}

.nav-fostrap li ul.dropdown li:hover a {
  background: rgba(0, 0, 0, 0.02);
}

.nav-fostrap li ul.dropdown li:first-child:hover a {
  border-radius: 0;
}

.nav-fostrap li:hover .arrow-down {
  border-top: 5px solid #fff;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fff;
  position: relative;
  top: 15px;
  right: -5px;
  content: "";
}

.title-mobile {
  display: none;
}

.mdl-layout__drawer .mdl-navigation ul.mdl-list li a.mdl-navigation__link {
  width: 100%;
}

#modal-agregar-paciente div.modal__text {
  min-height: 350px !important;
}

.mdl-inicio .mdl-color--primary,
.mdl-inicio .mdl-layout__header {
  background-color: #f1f1f182 !important;
  box-shadow: none;
  transition-duration: 0.2s;
}

.mdl-inicio .section-color {
  background-color: #009999;
  min-height: 200px;
}

.mdl-inicio a.ingresar {
  background-color: #e8c604;
  color: #fff;
  width: 100%;
  height: 100%;
}

.mdl-inicio a.ingresar div {
  margin-top: 12px;
}

.mdl-inicio button.ingresar i,
.mdl-inicio a.ingresar i {
  color: #fff;
}

.section-color span,
.section-color h5,
.section-color h4 {
  color: #fff;
}

.mdl-inicio .section1 {
  min-height: 400px;
}

.mdl-inicio .mdl-layout-title img {
  height: 40px;
}

.mdl-inicio .mdl-layout--fixed-drawer>.mdl-layout__content {
  padding: 0px;
}

.mdl-inicio footer {
  background-color: #eee;
}

.home-img .section1 h3 {
  margin-top: 16px;
  width: 90%;
}

.mdl-inicio .item-card .material-icons.med {
  font-size: 80px;
  color: #009999;
}

.mdl-inicio .item-card.mdl-card {
  background: none;
  box-shadow: none;
  border-radius: 15px;
}

.mdl-inicio .item-card span {
  color: #606060;
  font-weight: 600;
  font-size: 16px;
}

.mdl-inicio .img-dr {
  padding: 0px;
  margin: -8px;
  height: 400px;
  overflow: hidden;
}

.mdl-inicio .nav-login {
  height: 100%;
  background-color: #e8c604;
  margin-right: -10px;
  z-index: 2000;
}

.mdl-inicio .nav-login .mdl-button--icon {
  height: 52px;
  margin-left: 0;
  margin-right: 0;
  min-width: 48px;
  width: 48px;
  padding: 0;
  overflow: hidden;
  color: inherit;
  line-height: normal;
}

.mdl-inicio .home-light {
  background-color: #eee;
  min-height: 200px;
}

.slick-home {
  width: 80%;
  margin: 0 auto;
  padding: 20px 30px 20px 30px;
  min-height: 200px;
}

.slick-home h3,
.slick-home h4,
.slick-home h5 {
  padding: 20px;
  text-align: center;
}

.slick-home .slick-prev:before,
.slick-next:before {
  color: #009999;
}

.slick-home p {
  text-align: center;
}

.mdl-inicio .col-1 {
  padding: 20px;
}

footer a.login {
  color: #009999;
  text-decoration: underline;
  font-weight: 600;
}

.mdl-inicio .home-img .content {
  padding-left: 90px;
}

li.mdl-menu__par:hover {
  background-color: #efebeb !important;
  cursor: pointer !important;
}

li.mdl-menu__par {
  padding: 2px 2px 2px 10px;
}

#ms-select-agregar-paciente {
  display: none;
}

ul [for="select-tiempo"] {
  text-align: center;
}

#submenu li a.mdl-navigation__link {
  padding-left: 54px !important;
  color: #757575 !important;
}

#submenu li a.mdl-navigation__link i {
  color: #757575 !important;
}

@media screen and (max-width: 1024px) {
  .mdl-layout--fixed-drawer>.mdl-layout__content {
    margin-left: 0 !important;
  }

  .botonera button {
    padding: 2px 8px;
    font-size: 10px;
    width: auto;
  }

  div.dataTables_length {
    text-align: right;
  }

  .note-actions {
    float: right;
    width: auto;
  }

  .search .download-button {
    float: none;
    margin-top: 0px;
  }

  form.search button {
    margin-left: 10px;
    margin-top: 0px;
  }

  .profile-mobile {
    display: inline-block;
  }

  div.profile-mobile .mdl-list {
    height: 35px;
    padding-top: 0px;
    padding-bottom: 15px;
    padding-left: 20px;
  }

  div.profile-mobile a {
    height: 15px;
    color: var(--texto) !important;
  }

  div.profile-mobile {
    text-align: center;
  }

  div.profile-mobile .mdl-layout__drawer .mdl-navigation .mdl-navigation__link:hover {
    background-color: #ffffff;
  }

  div.profile-mobile span {
    color: var(--texto);
  }

  div.profile-mobile ul.mdl-list li.mdl-list__item a {
    color: var(--texto) !important;
  }

  div.profile-mobile .mdl-navigation__link:hover {
    background-color: #ffffff !important;
  }

  div.profile-mobile a.mdl-navigation__link i.material-icons {
    color: #fff;
  }

  .mdl-navigation {
    padding-top: 0px !important;
  }

  .mdl-inicio .mdl-layout__header {
    display: inline-block;
  }

  .header-title {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  form.search .filter {
    padding-left: 0px;
    display: inherit;
  }

  .mdl-inicio .nav-login {
    margin-top: 0px;
    margin-right: 0px;
  }

  .info-text {
    margin: auto;
  }
}

@media only screen and (max-width: 900px) {
  .nav-profile {
    margin-right: 0;
  }

  .arrow-down {
    border-top: 5px solid #505050;
    position: absolute;
    top: 20px;
    right: 10px;
  }

  .cover-bg {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

@media screen and (max-width: 760px) {
  .mdl-layout--fixed-drawer>.mdl-layout__content {
    margin-left: 0 !important;
  }

  .dataTables_filter input[type="search"] {
    padding: 14px;
    border: 1px solid #f1f1f1;
    width: 100%;
  }

  div.dataTables_length {
    display: inline;
  }

  #table-menu-principal {
    position: fixed;
    display: block;
    right: 0;
    bottom: 0;
    margin-right: 10px;
    margin-bottom: 10px;
    z-index: 900;
  }

  .mdl-button {
    font-size: 11px;
  }

  .menu-table {
    float: left;
  }

  .botonera {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .botonera button {
    padding: 2px 8px;
    font-size: 10px;
  }

  .mdl-dialog-med {
    width: 300px;
  }

  div.menu .child-menu-list {
    right: -14px !important;
    top: 44px !important;
    z-index: 99999999999999999999;
    background-color: #fff;
  }

  #monitoreo-general_wrapper .table-pag,
  #monitoreo-individual_wrappe .table-pag {
    height: 80px;
  }

  form.search button {
    margin-top: 5px;
    margin-left: 0px;
  }

  form.search div.mdl-textfield {
    margin: 0px;
  }

  .detalle-paciente .botonera {
    margin-top: 0px;
    float: none;
  }

  .detalle-paciente .botonera button {
    width: 100%;
  }

  .note-actions {
    float: none;
    width: 100%;
  }

  .info-text {
    text-align: center;
  }

  div.dataTables_wrapper div.dataTables_paginate {
    text-align: center !important;
  }

  div.dataTables_wrapper div.dataTables_info {
    text-align: center !important;
  }

  .search .download-button {
    float: right;
    margin-top: 5px;
  }

  .modal-med .modal__content,
  .modal-xl .modal__content {
    width: 330px;
  }

  #modal-select-hito .modal-med .modal__content,
  #modal-agregar-paciente .modal-xl .modal__content {
    margin-top: 40px;
  }

  .custom-header {
    margin: 0px 0px 3px 0px;
  }

  .custom-header span {
    color: #009999;
    font-size: 1rem;
    font-weight: 500;
  }

  .custom-header div:nth-child(1) {
    min-height: 40px;
  }

  .ms-container .ms-selectable li.ms-elem-selectable,
  .ms-container .ms-selection li.ms-elem-selection {
    font-size: 12px;
  }

  .ms-container ul.ms-list {
    line-height: 16px;
  }

  .btn-list {
    float: left;
  }

  .search-list {
    width: 75%;
  }

  .modal__text {
    width: 100%;
    padding: 1px;
  }

  .content-select2-multiple2-filter {
    width: 190%;
    max-width: none;
    float: none;
  }

  .mdl-demo .mdl-layout__header-row {
    padding-left: 72px;
  }

  .profile-mobile {
    display: inline-block;
    text-align: center;
  }

  div.profile-mobile .mdl-list {
    height: 35px;
    padding-top: 0px;
    padding-bottom: 15px;
    padding-left: 20px;
  }

  div.profile-mobile a {
    height: 15px;
    color: var(--texto) !important;
  }

  div.profile-mobile {
    background-color: white;
  }

  div.profile-mobile .mdl-layout__drawer .mdl-navigation .mdl-navigation__link:hover {
    background-color: #ffffff;
  }

  div.profile-mobile span {
    color: var(--texto);
  }

  div.profile-mobile ul.mdl-list li.mdl-list__item a {
    color: var(--texto) !important;
  }

  div.profile-mobile .mdl-navigation__link:hover {
    background-color: #ffffff !important;
  }

  div.profile-mobile a.mdl-navigation__link i.material-icons {
    color: #fff;
  }

  .mdl-navigation {
    padding-top: 0px !important;
  }

  .mdl-inicio .img-dr {
    padding: 0px;
    height: auto;
    overflow: hidden;
    margin: 8px;
  }

  .mdl-inicio img {
    width: 100%;
  }

  .mdl-inicio .mdl-layout__header {
    display: inline-block;
  }

  .mdl-inicio .mdl-layout-title {
    width: 50%;
  }

  .slick-home {
    width: 100%;
    margin: 0 auto;
    padding: 10px;
  }

  .slick-prev,
  .slick-next {
    display: none !important;
  }

  .mdl-inicio .item-card .material-icons.med {
    font-size: 50px;
  }

  .mdl-inicio .home-img .content {
    padding-left: 60px;
  }

  .mdl-inicio .mdl-mini-footer--right-section {
    width: 100%;
  }

  .mdl-inicio .mdl-mini-footer--right-section ul.mdl-mini-footer--link-list li {
    width: 100%;
  }

  .mdl-inicio .mdl-mini-footer--right-section ul.mdl-mini-footer--link-list li a {
    width: 100%;
    margin: 0 auto;
  }

  .mdl-inicio .mdl-layout__header-row {
    padding-left: 10px;
  }

  a.mdl-button,
  button.mdl-button {
    font-size: 1rem;
    margin-top: 10px;
  }

  form.search .filter {
    padding-left: 0px;
    display: block;
  }

  .mdl-inicio .nav-login {
    height: 100%;
    background-color: #e8c604;
    margin-top: -20px;
    margin-right: 0px;
  }

  #modal-agregar-paciente #select-parametro-paciente {
    width: 95%;
  }

  #modal-agregar-paciente .modal__text {
    font-size: 13px;
  }

  #modal-agregar-paciente .modal__title {
    height: 50px;
  }

  #modal-agregar-paciente div.contenido-modal {
    margin-top: -34px;
  }

  #modal-agregar-paciente .btn-list {
    float: left;
  }

  #modal-agregar-paciente.custom-header-filter span {
    font-size: 12px;
  }

  #modal-agregar-paciente .ms-selectable .custom-header-filter span {
    display: block;
    padding-left: 13px;
    font-size: 12px;
  }

  .header-title {
    width: unset;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 115px;
    transform: translate(0, -50%);
  }

  .mdl-layout__header span img {
    padding-left: 0 !important;
    width: 250px !important;
    height: auto;
  }

  table.dataTable>tbody>tr.child ul.dtr-details>li {
    border-bottom: none !important;
    white-space: normal !important;
  }
}

@media screen and (min-device-width: 320px) and (max-device-width: 568px) {

  .modal-med .modal__content,
  .modal-xl .modal__content {
    width: 310px;
  }

  .modal-xl .modal__dialog {
    margin-top: 110px;
  }
}

li.mdl-list__item {
  padding: 0 16px;
}

#canvas1,
#canvas2 {
  float: left;
}

.canvaspie .chartjs-size-monitor:first-child {
  display: none;
}

.grupos-cards table {
  background-color: transparent !important;
  border: none !important;
}

.grupos-cards thead {
  display: none;
}

.grupos-cards .c-item {
  width: auto;
  display: flex;
  padding: 2px 23px 0px;
  height: auto !important;
  font-size: 1rem;
  color: var(--texto);
}

.grupos-cards .c-item span:first-child {
  width: 51%;
}

.grupos-cards .c-item span:last-child {
  width: 49%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.grupos-cards .header-cards {
  width: auto;
  display: block;
  height: auto;
  padding: 23px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px 5px 0 0;
  margin-bottom: 5px;
  color: var(--primario);
  font-size: 22px;
  font-weight: 600;
  text-align: center;
}

.grupos-cards .footer-cards {
  width: auto !important;
  display: block;
  border-top: 1px solid #e4e4e4;
  padding: 2px 0px;
  height: auto !important;
  text-align: center;
  margin-top: 5px;
}

.grupos-cards .header-cards .tooltip-exportart {
  float: right;
  margin-right: 21px;
}

.grupos-cards .header-cards .ico-grupo {
  float: right;
  color: #009999;
}

.grupos-cards .header-cards .tooltip-exportart:hover {
  -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: rgba(158, 158, 158, 0.2);
}

.grupos-cards .header-cards .tooltip-exportart:hover::before {
  content: "Exportar";
  position: absolute;
  z-index: 999;
  background: rgba(97, 97, 97, 0.9);
  border-radius: 2px;
  color: #fff;
  padding: 8px;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  max-width: 170px;
  bottom: -11px;
  right: 27px;
  display: inline-block;
}

.no-button {
  display: none;
}

.input-grupos {
  margin: 4px;
}

button.input-grupos {
  position: absolute;
  right: 2%;
  top: 35%;
}

.form-grupo .mdl-textfield {
  width: 250px !important;
}

.form-grupo {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  background-color: #fff;
  margin: 0.5rem;
  position: relative;
}

.form-det-monitoreo div:last-child {
  position: relative;
}

.form-det-monitoreo button.search {
  position: absolute;
  top: 19%;
}

.form-footer {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  background-color: #fff;
  margin: 0.5rem;
}

.form-footer .dataTables_length,
.form-footer .dataTables_info {
  margin-top: 6px;
}

.menuAcciones ul {
  padding: 0 !important;
  margin: 0 !important;
}

.menuAcciones li {
  list-style: none !important;
  height: auto !important;
  padding: 0 !important;
}

.menuAcciones li a {
  display: block;
  border: none;
  color: var(--texto);
  text-align: left;
  margin: 0;
  padding: 0 16px;
  outline-color: #bdbdbd;
  position: relative;
  overflow: hidden;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0;
  text-decoration: none;
  cursor: pointer;
  line-height: 30px;
  white-space: nowrap;
  opacity: 1;
  height: auto !important;
}

.menuAcciones hr {
  margin: 0 !important;
}

.mdl-layout__header span img {
  display: block !important;
  padding-left: 110px;
  padding-right: 0;
  width: auto;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}

.mdl-inicio .mdl-layout__header-row {
  background-color: white !important;
  padding: 0;
}

#form-bloqueo .mat-mdc-form-field {
  width: 100%;
}

.mat-mdc-form-field.mat-mdc-focused .mat-mdc-form-field-label,
.mat-mdc-form-field.mat-mdc-focused.mat-mdc-primary .mat-mdc-select-arrow {
  color: var(--primario) !important;
}

.mat-mdc-form-field.mat-mdc-focused .mat-mdc-form-field-ripple,
.mat-mdc-calendar-body-selected {
  background-color: var(--primario) !important;
}

.mat-mdc-datepicker-toggle-active {
  color: var(--primario);
}

.mat-mdc-accent .mat-mdc-pseudo-checkbox-checked,
.mat-mdc-accent .mat-mdc-pseudo-checkbox-indeterminate,
.mat-mdc-pseudo-checkbox-checked,
.mat-mdc-pseudo-checkbox-indeterminate {
  background-color: var(--primario) !important;
}

.mat-mdc-slide-toggle.mat-mdc-checked:not(.mat-mdc-disabled) .mat-mdc-slide-toggle-thumb {
  background-color: var(--primario) !important;
}

.mat-mdc-calendar-body-today.mat-mdc-calendar-body-selected {
  box-shadow: none;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.mat-mdc-snack-bar-container span {
  color: #fff !important;
}

.mat-mdc-simple-snackbar-action button {
  border: 1px solid #fff !important;
}

.mdl-textfield {
  width: 100%;
}

.mat-mdc-form-field {
  width: 100%;
}

@media (max-width: 575.98px) {

  .modal-med .modal__content,
  .modal-xl .modal__content {
    width: 100%;
  }

  .grupos-cards .cards {
    width: 100%;
    margin-bottom: 5%;
  }

  .grupos-cards .header-cards {
    font-size: 18px;
  }
}

.mat-mdc-checkbox-checked.mat-mdc-accent .mat-mdc-checkbox-background,
.mat-mdc-checkbox-indeterminate.mat-mdc-accent .mat-mdc-checkbox-background {
  background-color: var(--primario) !important;
}

.mat-mdc-slide-toggle.mat-mdc-checked:not(.mat-mdc-disabled) .mat-mdc-slide-toggle-bar {
  background-color: var(--terciario) !important;
}

.form-grupo .mat-mdc-form-field {
  width: 95%;
}

@media (max-width: 575.98px) {

  .modal-med .modal__content,
  .modal-xl .modal__content {
    width: 100%;
  }

  .mdl-cell.canvaspie.mdl-cell--12-col {
    width: 86% !important;
    overflow: hidden;
  }

  .canvaspie canvas#canvasPie {
    width: 280px !important;
    height: 280px !important;
  }

  .groupeter th {
    padding: 0 5px 12px 5px !important;
  }

  .table-pag {
    height: auto;
  }

  div#monitoreo-individual-par1_filter {
    width: 100% !important;
  }

  #monitoreo-individual-par1_wrapper .dt-buttons {
    width: 100% !important;
  }

  .dropdown-list {
    width: 106% !important;
  }

  .form-det-monitoreo button.search {
    position: initial;
    top: 0;
  }

  .color-picker {
    left: 45.094px !important;
  }

  .color-picker .arrow.arrow-top {
    left: 108px !important;
  }

  #modal-agregar-paciente {
    margin: 0px;
  }

  .form-paciente {
    margin: -15px;
  }

  .active-acceso::after {
    content: "";
    width: 39px !important;
    background: #fff;
    position: absolute;
    height: 4px;
    bottom: -20px;
    left: 11px;
  }

  .acceso {
    display: block !important;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .grupos-cards .cards {
    width: 100%;
    margin: 0.5rem 0;
  }

  .modal-med .modal__content,
  .modal-xl .modal__content {
    width: 100%;
  }

  .mdl-cell.canvaspie.mdl-cell--12-col {
    width: 86% !important;
    overflow: hidden;
  }

  .canvaspie canvas#canvasPie {
    width: 248px !important;
    height: 248px !important;
  }

  .groupeter th {
    padding: 0 5px 12px 5px !important;
  }

  .table-pag {
    height: auto;
  }

  div#monitoreo-individual-par1_filter {
    width: 100% !important;
  }

  #monitoreo-individual-par1_wrapper .dt-buttons {
    width: 100% !important;
  }

  .dropdown-list {
    width: 106% !important;
  }

  .form-det-monitoreo button.search {
    position: initial;
    top: 0;
  }

  .color-picker {
    left: 45.094px !important;
  }

  .color-picker .arrow.arrow-top {
    left: 108px !important;
  }

  #modal-mensaje .modal__content {
    width: 100%;
  }

  #modal-agregar-paciente {
    margin: 0px -10px 0px -10px;
  }

  .form-paciente {
    margin: -15px;
  }

  .mdl-textfield {
    width: 248px !important;
    max-width: 120% !important;
  }

  .active-acceso::after {
    content: "";
    width: 39px !important;
    background: #fff;
    position: absolute;
    height: 4px;
    bottom: -16px;
    left: 11px;
  }

  .acceso {
    display: block !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .grupos-cards .cards {
    float: left;
    width: 48%;
    height: auto !important;
    margin: 1%;
    background-color: #fff !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }

  .table-pag {
    height: auto;
  }

  .form-det-monitoreo button.search {
    position: initial;
    top: 0;
  }

  #modal-agregar-paciente {
    margin: 0px -10px 0px -10px;
  }

  .form-paciente {
    margin: -15px;
  }

  .mdl-textfield {
    width: 248px !important;
    max-width: 120% !important;
  }

  .active-acceso::after {
    content: "";
    width: 39px !important;
    background: #fff;
    position: absolute;
    height: 4px;
    bottom: -16px;
    left: 11px;
  }

  .acceso {
    display: block !important;
  }
}

.inputcustom {
  width: 100%;
  position: relative;
}

.getmdl-select {
  outline: none;
}

.getmdl-select .selected {
  background-color: #ddd;
}

.getmdl-select .mdl-icon-toggle__label {
  float: right;
  margin-top: -30px;
  color: rgba(0, 0, 0, 0.4);
  transform: rotate(0);
  transition: transform 0.3s;
}

.getmdl-select .mdl-menu__container {
  width: 100% !important;
  margin-top: 2px;
}

.getmdl-select .mdl-menu__container .mdl-menu {
  width: 100%;
}

.getmdl-select .mdl-menu__container .mdl-menu .mdl-menu__item {
  font-size: 16px;
}

.getmdl-select__fix-height .mdl-menu__container .mdl-menu {
  overflow-y: auto;
  max-height: 288px !important;
}

.getmdl-select__fix-height .mdl-menu.mdl-menu--top-left {
  bottom: auto;
  top: 0;
}

table.dataTable tbody tr.selected,
table.dataTable tbody th.selected,
table.dataTable tbody td.selected {
  color: unset !important;
}

table.dataTable tbody>tr.selected,
table.dataTable tbody>tr>.selected {
  background-color: #eeeeee40 !important;
}

.modal__footer {
  box-sizing: border-box;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.dataTables_scrollHeadInner {
  width: 100% !important;
}

.dataTables_scrollHeadInner .dataTable {
  width: 100% !important;
}

#monitoreo-individual-par1_wrapper button.dt-button.buttons-excel.buttons-html5 {
  background-color: var(--primario) !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border: none;
  float: right;
  padding: 18px 21px 18px 36px;
  line-height: 0 !important;
  font-weight: bold;
  font-family: "Muli", sans-serif;
}

#monitoreo-individual-par1_wrapper button.dt-button.buttons-excel.buttons-html5 span {
  color: #fff;
}

#monitoreo-individual-par1_wrapper button.dt-button.buttons-excel.buttons-html5 span:before {
  content: "file_copy";
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  position: absolute;
  top: 14px;
  left: 11px;
  font-family: "Material Icons", sans-serif;
}

#monitoreo-individual-par1_wrapper button.dt-button:active:not(.disabled):hover:not(.disabled),
#monitoreo-individual-par1_wrapper button.dt-button.active:not(.disabled):hover:not(.disabled),
#monitoreo-individual-par1_wrapper div.dt-button:active:not(.disabled):hover:not(.disabled),
#monitoreo-individual-par1_wrapper div.dt-button.active:not(.disabled):hover:not(.disabled),
#monitoreo-individual-par1_wrapper a.dt-button:active:not(.disabled):hover:not(.disabled),
#monitoreo-individual-par1_wrapper a.dt-button.active:not(.disabled):hover:not(.disabled) {
  background-image: none !important;
}

div#monitoreo-individual-par1_filter {
  width: 50%;
  float: left;
}

#monitoreo-individual-par1_wrapper .dt-buttons {
  width: 50%;
}

.graf-p-1 {
  width: 50%;
}

.graf-p-2 {
  width: 50%;
  margin-left: -16px;
}

.graf-p-1,
.graf-p-2 {
  padding: 0px;
}

.graf-p {
  margin: 5px;
}

.tooltip {
  position: relative;
  display: inline-block;
  opacity: 1 !important;
}

.tooltip [class^="tooltiptext"] {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, calc(-100% - 10px));
  width: max-content;
  max-width: 200px;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  background-color: var(--texto);
  color: #fff;
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 2px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.75s;
  z-index: 10;
}

.tooltip .tooltiptext-bottom {
  margin-top: 10px;
  left: 50%;
  transform: translate(-50%, calc(100% + 10px));
  bottom: 0;
}

.tooltip .tooltiptext-right {
  left: 120%;
  top: -5px;
}

.tooltip .tooltiptext-left {
  top: 50%;
  right: calc(100% + 0.5rem);
  left: initial;
  bottom: initial;
  transform: translate(0, -50%);
  margin: 0;
}

.tooltip .tooltiptext-nota {
  left: 50%;
  transform: translate(-50%, calc(-100% - 10px));
  top: 0;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  width: 200px;
}

.tooltip .tooltiptext-preview {
  margin-bottom: 10px;
  border: solid 1px var(--texto);
  left: 50%;
  transform: translate(-50%, calc(-100% - 10px));
  top: 0;
  -webkit-transform: translate(-50%, calc(-100% - 10px));
  width: 160px;
}

.tooltip:hover [class^="tooltiptext"] {
  visibility: visible !important;
  opacity: 1 !important;
}

.mat-mdc-badge-cas .mat-mdc-badge-content {
  color: #fff;
  background: #304ffe;
}

.dropdown-list {
  padding-top: 0 !important;
  position: relative !important;
  width: 104% !important;
}

.dropdown-list .arrow-2 {
  display: none;
}

button.btn.btn-primary.pull-left {
  text-decoration: underline;
}

button.btn.btn-primary.btn-block.point-left {
  color: #fff;
  background-color: var(--primario);
  height: 24px;
  line-height: 24px;
  font-size: 11px;
  border-color: var(--primario);
  border: 1px solid var(--primario);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

button.btn.btn-primary.pull-left:hover,
button.btn.btn-default.pull-right:hover {
  border: solid 1px var(--primario);
  color: var(--primario);
}

button.btn.btn-default.pull-right {
  text-decoration: underline;
}

.pull-right::after {
  float: left;
  content: "\2296";
  font-size: 20px;
}

.pull-left::after {
  float: left;
  content: "\2295";
  font-size: 20px;
}

.point-right::after {
  content: "\2197" !important;
  font-size: 20px;
}

.point-left::before {
  content: "\2199" !important;
  font-size: 20px;
}

button.btn.btn-primary.btn-block.point-right {
  color: #fff;
  background-color: #1d76d0;
  height: 24px;
  line-height: 24px;
  font-size: 11px;
  border-color: #1d76d0;
  border: 1px solid #1d76d0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.record-picker {
  border: none !important;
  box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.14),
    0 3px 1px 0px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
  border-radius: 2px !important;
}

.dataTables_wrapper.form-inline {
  display: block !important;
}

.selected-list .c-list .c-token {
  background: var(--secundario) !important;
}

.pure-checkbox input[type="checkbox"]:checked+label:before {
  background: var(--primario) !important;
}

.pure-checkbox input[type="checkbox"]+label:before {
  color: var(--primario) !important;
  border: 2px solid var(--primario) !important;
}

.grupos-cards .dataTables_length label {
  float: left;
  margin-left: 6px;
}

.grupos-cards .dataTables_info {
  text-align: center;
}

.black-overlay {
  position: fixed !important;
}

.mat-mdc-snack-bar-container {
  background: #1d76d0 !important;
}

.mat-mdc-snack-bar-container.info .mdc-snackbar__surface,
.mat-mdc-snack-bar-container .info {
  background: var(--info-color) !important;
}

.success,
.mat-mdc-snack-bar-container.success .mdc-snackbar__surface,
.mat-mdc-snack-bar-container .success {
  background: var(--success-color) !important;
}

.warn,
.mat-mdc-snack-bar-container.warn .mdc-snackbar__surface,
.mat-mdc-snack-bar-container .warn {
  background: var(--warn-color) !important;
}

.error,
.mat-mdc-snack-bar-container.error .mdc-snackbar__surface,
.mat-mdc-snack-bar-container .error {
  background: #b00020 !important;
}

.mat-mdc-standard-chip.mat-mdc-chip-with-trailing-icon {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

span.group-info {
  font-weight: 500 !important;
}

h5.group-name-title {
  margin: 5px 0 15px !important;
  font-weight: 400 !important;
}

.group-main-tittle {
  font-size: 34px !important;
  line-height: 40px !important;
  font-weight: 500 !important;
  color: #1d76d0 !important;
}

.group-main-tittle>i {
  font-size: 34px !important;
  line-height: 40px !important;
  font-weight: 500 !important;
  color: #1d76d0 !important;
}

@media screen and (max-width: 1025px) {
  .datos .value-data {
    width: calc(25% - 16px) !important;
  }

  .monitoreo-table .c-item .dato .title-data {
    width: calc(100% - 16px) !important;
  }
}

.datosTable thead {
  display: block !important;
}

.header-cards {
  min-height: 57px !important;
}

.monitoreo-table .cards {
  float: left;
  height: auto !important;
  margin: 0.5rem;
}

.monitoreo-table .header-cards {
  width: auto;
  display: block;
  height: auto;
  padding: 23px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 5px;
  background-color: white !important;
}

.monitoreo-table td .detalle .prom {
  border-bottom: 0.1em solid #009999 !important;
  width: 100px !important;
  font-weight: 500 !important;
}

.monitoreo-table .header-cards span a {
  color: var(--primario);
  font-weight: 700;
  text-align: justify;
  white-space: normal !important;
}

.monitoreo-table thead {
  display: none;
}

.monitoreo-table tbody {
  background-color: transparent !important;
}

.monitoreo-table {
  background-color: transparent !important;
  border: none !important;
}

.monitoreo-table tr {
  padding: 4px;
  background-color: transparent !important;
}

.table-monitoreo-list .dtr-details li {
  min-height: 70px !important;
  min-width: 155px !important;
  border-bottom: none !important;
  margin-bottom: 20px;
  float: left;
  margin-left: 15px;
  margin-right: 15px;
}

@media (max-width: 839px) and (min-width: 480px) {
  .table-monitoreo-list .dtr-details li {
    min-height: 70px !important;
    min-width: 180px !important;
  }
}

.table-monitoreo-list .dtr-details .dtr-title {
  color: var(--texto);
  border: 1px #c7c7c7 solid;
  border-right: 0;
  border-left: 0;
  display: block;
  text-align: center;
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 700;
  margin: 1px 0;
  width: 100%;
}

.monitoreo-table td {
  background-color: #fff !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  margin-bottom: 0 !important;
  border-bottom: 1px solid #dfdfdf !important;
  border-left: 1px solid #dfdfdf !important;
  border-right: 1px solid #dfdfdf !important;
  border-top: none;
}

.datos {
  padding: 0px !important;
  margin: initial !important;
  width: calc(100% - 16px) !important;
}

.datos div {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  text-align: center;
}

.datos div:first-child {
  text-align: left;
  font: bold;
  white-space: normal;
}

.monitoreo-table .c-item {
  display: flex;
  width: auto;
  height: auto !important;
  min-height: 65px !important;
  max-height: 65px !important;
  font-size: 12px;
  color: var(--texto);
}

.monitoreo-table .header-cards:hover {
  background-color: white !important;
  cursor: pointer;
}

.edad-sexo span {
  width: 100%;
}

.monitoreo-table .edad-sexo {
  width: auto;
  display: flex;
  height: auto !important;
  font-size: 1rem;
  color: var(--texto);
  text-align: center;
}

.numeros-datos span {
  color: var(--texto) !important;
}

.btn-email {
  float: right;
  margin-top: -10px;
  margin-right: -16px;
}

.monitoreo-table .header-table {
  background-color: #f5f5f5 !important;
  color: var(--texto);
  height: 32px !important;
  min-height: 32px !important;
  max-height: 32px !important;
  border-bottom: 1px solid #dfdfdf !important;
  border-left: 1px solid #dfdfdf !important;
  border-right: 1px solid #dfdfdf !important;
  border-top: none;
}

.monitoreo-table .header-table .datos {
  width: 100%;
}

i.sm-right-corner {
  font-size: 30px !important;
  float: right;
  opacity: 0.5;
}

.item-card:hover .content h5 i {
  opacity: 1;
}

.active-link::after {
  content: "";
  width: 80% !important;
  background: #fff;
  position: absolute;
  height: 3px;
  bottom: -8px;
  left: 8px;
}

.acceso a {
  padding: 0 0.5em;
  position: relative;
}

.acceso {
  display: none;
}

@media screen and (max-width: 1024px) {
  .mdl-layout__header {
    display: block !important;
  }
}

.pac-title {
  font-weight: 600;
  color: #1d76d0;
  margin: 2px 0px 2px 0px;
  font-family: "Muli", sans-serif;
  font-size: 34px;
  line-height: 40px;
  padding: 0;
}

.menu-burger-active {
  display: block;
}

.menu-burger-disable {
  display: none;
}

.botonera-pac {
  display: inline-block;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .acceso {
    margin-left: 23px;
  }
}

.pagination .mdl-button {
  min-width: 55px;
}

.pt-20 {
  padding-top: 20px;
}

div.img-preview {
  margin: auto;
  width: 100%;
}

div.div-center {
  margin: auto;
  width: auto;
  text-align: center;
}

div.div-center div {
  font-weight: bold;
}

.mat-mdc-standard-chip.square-chip {
  padding: 1px 5px !important;
  background-color: #91d3d36b !important;
}

.mat-mdc-primary .mat-mdc-option.mat-mdc-selected:not(.mat-mdc-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  font-family: "Font Awesome 5 Pro", sans-serif;
  font-size: 20px;
  content: "\f0d7";
  opacity: 0.3;
  right: 5px;
  top: 21px;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  font-size: 20px;
  font-family: "Font Awesome 5 Pro", sans-serif;
  content: "\f0d8";
  opacity: 0.3;
  right: 5px;
  bottom: 12px;
}

table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:after {
  color: var(--primario);
  opacity: 1;
}

div.dataTables_scrollBody table thead .sorting:before,
div.dataTables_scrollBody table thead .sorting_asc:before,
div.dataTables_scrollBody table thead .sorting_desc:before,
div.dataTables_scrollBody table thead .sorting:after,
div.dataTables_scrollBody table thead .sorting_asc:after,
div.dataTables_scrollBody table thead .sorting_desc:after {
  display: none !important;
}

.cabecera-dashboard {
  max-height: 53px !important;
}

.cdk-global-overlay-wrapper {
  overflow-y: hidden;
}

.box-etario .mdl-card .dataTables_scrollBody {
  height: 100% !important;
}

.box-etario .mdl-demo .mdl-card.full-height {
  height: 100%;
}

.box-etario .mdl-data-table th {
  height: unset !important;
}

.box-etario .mdl-data-table tbody tr {
  height: unset !important;
}

.box-etario.mdl-card>* {
  height: 100% !important;
}

.mat-mdc-expansion-panel-content>.mat-mdc-expansion-panel-body {
  padding: 0 !important;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

#list-hitos th,
#list-grupos th {
  text-align: center;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

#list-hitos td {
  text-align: center;
  padding: 8px 12px !important;
}

#list-grupos td {
  height: unset !important;
  text-align: center;
  padding: 0 12px !important;
}

.cdk-overlay-connected-position-bounding-box .cdk-overlay-pane {
  transform: none !important;
  box-sizing: content-box;
  padding: 4px 4px 10px 5px;
  margin-left: -5px;
  margin-top: -2px;
}

.cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-select-panel {
  margin-top: 0;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 0 !important;
  min-width: 100% !important;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: var(--primario);
}

.mdc-menu-surface {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .25);
  border-bottom: 1px solid rgba(0, 0, 0, .15);
  border-right: 1px solid rgba(0, 0, 0, .15);
  border-left: 1px solid rgba(0, 0, 0, .15);
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  min-height: max-content;
  width: 100%;
  padding: 0;
}

.mat-mdc-form-field .mdc-text-field__input,
.mat-mdc-form-field .mat-mdc-select {
  padding-top: 10px !important;
  padding-bottom: 7px !important;
  margin-top: 5px;
}

.mat-mdc-form-field .mdc-text-field__input {
  padding-top: 12px !important;
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
}

.mat-mdc-form-field-focus-overlay {
  background: transparent !important;
}

.cdk-overlay-pane-select-search {
  margin: 35px 0px !important;
}

.mat-mdc-select-search-hidden {
  display: none !important;
}

.mat-mdc-option.select-all {
  border-bottom: 1px solid #ccc;
  height: 3.5em;
  line-height: 3.5em;
}

.cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-select-panel.ng-animating {
  display: none;
}

.mat-mdc-progress-bar-fill::after {
  background-color: var(--primario);
}

.mat-mdc-progress-bar-buffer {
  background-color: var(--primario);
  opacity: 0.5;
}

.mat-mdc-progress-bar-background {
  fill: var(--primario);
  opacity: 0;
}

.mat-mdc-form-field,
.mat-mdc-select,
.mat-mdc-option,
.mdc-text-field--filled .mdc-floating-label {
  font-family: "Muli", sans-serif !important;
  font-size: 1rem;
}

.botones-busqueda button {
  float: right;
}

.icono-bloqueo {
  margin-left: 10px;
}

.mdl-data-table {
  width: 100%;
  border: none;
}

.mdl-data-table th {
  color: var(--texto);
  width: 100%;
  border: none;
  font-size: 16px;
}

.bloqueo-notificaciones {
  display: flex;
  align-items: center;
}

.mat-mdc-slide-toggle-content {
  font-family: "Muli", sans-serif;
}

.mat-mdc-form-field-wrapper {
  padding-bottom: 0;
}

.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline-thick {
  color: #c6c6c6;
}

.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline-thick .mat-mdc-form-field-outline-end,
.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline-thick .mat-mdc-form-field-outline-gap,
.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline-thick .mat-mdc-form-field-outline-start {
  border-width: 1px !important;
}

.mat-mdc-form-field-appearance-outline.mat-mdc-focused .mat-mdc-form-field-outline-thick {
  color: var(--primario);
}

.mat-mdc-tab-label {
  opacity: 1 !important;
}

.mat-mdc-tab-group.mat-mdc-primary .mat-mdc-ink-bar,
.mat-mdc-tab-nav-bar.mat-mdc-primary .mat-mdc-ink-bar {
  background-color: var(--primario);
}

.mat-mdc-select-search-inner {
  box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.2) !important;
}

.info-text-center {
  margin: auto !important;
}

.mat-mdc-radio-button.mat-mdc-accent.mat-mdc-radio-checked .mat-mdc-radio-outer-circle {
  border-color: var(--secundario);
}

.mat-mdc-radio-button.mat-mdc-accent .mat-mdc-radio-inner-circle {
  color: var(--secundario);
  background-color: var(--secundario);
}

.mat-mdc-radio-button.mat-mdc-accent .mat-mdc-radio-ripple .mat-mdc-ripple-element {
  background-color: var(--secundario);
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--texto) !important;
}

.mat-mdc-select-placeholder,
.mat-mdc-select-arrow,
.mat-mdc-option .mdc-list-item__primary-text {
  color: var(--texto);
}

.cerrar-modal {
  position: absolute;
  display: flex !important;
  justify-content: center;
  align-items: center;
  top: 1rem;
  right: 1rem;
  width: 30px;
  height: 30px;
  font-size: 1rem;
  color: var(--texto);
  cursor: pointer;
  z-index: 10;
}

.titulo-modal {
  font-family: "Muli", sans-serif;
  color: var(--primario);
  font-weight: 600;
}

mat-mdc-dialog-container mat-mdc-progress-bar {
  margin: -24px -24px 5px -24px;
}

mat-mdc-dialog-container .mat-mdc-progress-bar {
  width: unset !important;
}

.info-hover {
  color: var(--terciario);
}

.ver-detalle-paciente {
  color: var(--primario);
  font-weight: 600;
}

.swal2-title {
  font-size: 18px !important;
  color: var(--texto) !important;
  font-family: "Muli", sans-serif !important;
}

*,
*::before,
*::after {
  outline: none !important;
}

.mat-mdc-select,
input.mat-mdc-input-element {
  line-height: 1.35 !important;
}

.cdk-overlay-pane.indice-modal,
.cdk-overlay-pane.modal-full-screen {
  min-width: calc(100% - 2rem);
  width: calc(100% - 2rem);
  min-height: calc(100% - 2rem);
  height: calc(100% - 2rem);
}

.cdk-overlay-pane.modal-history-meditions {
  width: 100%;
}

.cdk-overlay-pane.indice-modal .mat-mdc-dialog-content {
  max-height: initial !important;
  height: max-content !important;
  overflow: hidden !important;
}

*::-webkit-scrollbar,
*::before::-webkit-scrollbar,
*::after::-webkit-scrollbar,
.mat-mdc-dialog-content::-webkit-scrollbar {
  background: transparent !important;
  width: 6px !important;
}

*::-webkit-scrollbar-thumb,
*::before::-webkit-scrollbar-thumb,
*::after::-webkit-scrollbar-thumb,
.mat-mdc-dialog-content::-webkit-scrollbar-thumb {
  background: var(--primario) !important;
  border: 2px solid var(--barra) !important;
  border-radius: 20px !important;
}

.mdl-layout__header-row>* {
  height: 78%;
}

@media only screen and (max-width: 992px) {

  *::-webkit-scrollbar,
  *::before::-webkit-scrollbar,
  *::after::-webkit-scrollbar {
    display: none;
  }
}

@media only screen and (max-width: 766px) {
  .mdl-layout__header-row {
    padding: 0 16px !important;
  }
}

.mdl-data-table tbody tr:hover {
  background: var(--fondo) !important;
}

.mdl-card,
.mdl-layout__drawer,
.mdl-data-table,
.mat-mdc-dialog-container,
.table-pag {
  background: var(--barra) !important;
}

.mat-mdc-dialog-container {
  border-radius: 0.75rem !important;
  overflow: hidden;
}

.mat-mdc-select-value,
.mat-mdc-input-element,
.mat-mdc-form-field-label {
  color: var(--texto) !important;
}

.mat-mdc-input-element::placeholder {
  color: var(--texto) !important;
}

.mat-mdc-form-field-label {
  line-height: 1.2 !important;
}

.table-pag {
  width: 100% !important;
  justify-content: space-between !important;
  align-items: center !important;
  height: 60px;
  border-top: none;
  background-color: #fff;

}

.table-pag>.mdl-cell {
  width: max-content !important;
  margin: 0 !important;
}

.mdc-text-field {
  padding: 0 !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

.mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-underline {
  background-color: var(--texto) !important;
}

.cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-select-panel,
option,
.gjs-one-bg,
.mat-mdc-datepicker-content,
.nav-fostrap li ul.dropdown,
.mat-mdc-menu-panel {
  background: var(--fondo) !important;
}

.mat-mdc-option,
.mat-mdc-option.mat-mdc-active,
table.dataTable tbody>tr>td,
.dataTables_length label,
div.dataTables_wrapper div.dataTables_info,
.mat-mdc-calendar-body-label,
.mat-mdc-calendar-table-header th,
.mat-mdc-calendar-body-cell-content {
  color: var(--texto) !important;
}

.mat-mdc-form-field-appearance-legacy .mat-mdc-hint {
  color: var(--texto) !important;
  opacity: 0.85 !important;
}

.mat-mdc-pseudo-checkbox {
  border: 2px solid var(--texto) !important;
}

.mat-mdc-pseudo-checkbox.mat-mdc-pseudo-checkbox-checked,
.mat-mdc-pseudo-checkbox.mat-mdc-pseudo-checkbox-indeterminate {
  border-color: transparent !important;
}

.mdl-button[disabled][disabled],
.mdl-button.mdl-button--disabled.mdl-button--disabled {
  color: var(--texto) !important;
  opacity: 0.26 !important;
}

/* .mat-mdc-button,
.mat-mdc-icon-button,
.mat-mdc-stroked-button,
.mat-mdc-flat-button {
  color: var(--texto-boton) !important;
} */

.mat-mdc-calendar-arrow {
  border-top-color: var(--texto) !important;
  opacity: 0.85 !important;
}

.mat-mdc-calendar-body-today:not(.mat-mdc-calendar-body-selected) {
  border-color: var(--texto) !important;
  opacity: 0.85 !important;
}

.grupos-cards .cards {
  background-color: var(--barra) !important;
  border-radius: 5px;
}

.mdl-layout__drawer .mdl-navigation .mdl-navigation__link:hover {
  background-color: rgba(0, 0, 0, 0.25) !important;
  text-decoration: none;
}

.mdl-button--accent.mdl-button--accent.mdl-button--raised[_ngcontent-yfg-c201]:disabled {
  background: rgb(0, 0, 0) !important;
}

.cdk-overlay-pane.modal-full-screen.modal-grafica .mat-mdc-dialog-content {
  max-height: initial !important;
}

.wrapper-title-app {
  position: relative;
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 0 auto 10px !important;
  padding: 10px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  height: max-content;
  z-index: 100;
}

.wrapper-title-app.mdc-dialog__title {
  padding: 10px 0 !important;
}

.wrapper-title-app .title-app {
  display: block;
  width: 100%;
  text-align: left;
  font-weight: bold;
  font-size: 1.55rem;
  line-height: 1.2;
  color: var(--texto);
  opacity: 0.85;
  margin: 0;
  padding: 0 0 0 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.wrapper-title-app .fecha-app {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  font-size: 0.95rem;
  color: var(--texto);
  text-transform: capitalize;
  opacity: 0.85;
}

.wrapper-title-app .btn-app {
  display: block;
  width: max-content;
  height: 34px;
  line-height: 34px;
  padding: 0 0.7rem;
  padding-left: 0;
  border: none;
  color: var(--secundario);
  background: transparent;
  box-shadow: none !important;
  outline: none !important;
  border-radius: 12px;
  font-size: 1.35rem;
  cursor: pointer;
}

.subtitle-app,
.parrafo-app {
  display: block;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0 0 1.5rem;
  font-size: 1.45rem;
  font-weight: bold;
  color: var(--texto);
  opacity: 0.85;
}

.parrafo-app {
  font-size: .95rem;
  font-weight: normal;
  text-align: left;
  padding: 0;
}

.mdl-button--accent.mdl-button--accent.mdl-button--fab,
.mdl-button--accent.mdl-button--accent.mdl-button--raised {
  color: var(--texto-boton);
  background-color: var(--secundario);
  text-transform: none !important;
  box-shadow: none !important;
}

.mat-mdc-menu-item[disabled],
.mat-mdc-menu-item[disabled]::after {
  color: var(--texto) !important;
  opacity: 0.39 !important;
}

.mat-mdc-checkbox-frame {
  border-color: var(--texto) !important;
}

.mat-mdc-expansion-panel {
  background: var(--fondo) !important;
}

.form-control {
  height: calc(.25rem + 0.75rem + 6px) !important;
  line-height: calc(.25rem + 0.75rem + 6px) !important;
  background: transparent !important;
  color: var(--texto) !important;
  border: none !important;
  box-shadow: none !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  opacity: 0.6;
  filter: var(--calendar-color);
}

input[type="date"]::-webkit-calendar-picker-indicator:hover {
  opacity: 1
}

.mdl-data-table .mat-mdc-icon-button {
  color: var(--texto) !important;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  background-color: rgba(0, 0, 0, 0.15);
  color: var(--texto);
}

.nav-lateral-app {
  position: fixed;
  left: 0;
  bottom: 0;
  width: var(--width-nla);
  height: calc(100vh - (60px + 1rem));
  background: var(--primario);
  margin-left: 1rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 0.25rem;
  transition: all 0.15s ease-in-out;
  z-index: 100;
}

.nav-lateral-app .options {
  display: block;
  width: 100%;
  height: max-content;
  padding: 0.5rem;
}

.nav-lateral-app .options .dividir {
  width: 100%;
  height: 1px;
  background: white;
  opacity: 0.85;
  margin: 1rem 0;
  padding: 0;
}

.nav-lateral-app .options__link {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: max-content;
  font-weight: lighter;
  color: white;
  padding: 0.25rem 0.9rem;
  margin: 0 0 0.75rem;
  font-size: 0.95rem;
  opacity: 0.85;
  text-decoration: none !important;
  transition: opacity 0.15s ease-in-out;
}

.nav-lateral-app .options__link .icon {
  width: 30px;
  font-size: 1rem;
  text-align: center;
  margin-right: 0.25rem;
  margin-left: -0.25rem;
}

.nav-lateral-app .options__link p {
  display: block;
  margin: 0;
  text-align: left;
  color: inherit;
}

.nav-lateral-app .options__link:hover {
  background: rgba(0, 0, 0, .065);
  border-radius: 5px;
  opacity: 1;
}

.nav-lateral-app.nav-lateral-app--collapse .options__link:hover {
  background: transparent;
  border: none;
}

.nav-lateral-app.nav-lateral-app--collapse .options__link {
  margin: 0 0 1.5rem;
  justify-content: center;
}

.nav-lateral-app.nav-lateral-app--collapse .options__link .icon {
  width: initial;
  font-size: 1.2rem;
  padding: 0;
  margin: 0;
}

.nav-lateral-app.nav-lateral-app--collapse .options__link p {
  display: none;
}

.mdl-layout__content {
  width: calc(100vw - (var(--width-nla) + 1rem));
  height: calc(100vh - (60px));
  max-height: calc(100vh - (60px));
  margin-top: calc(60px);
  margin-left: calc(var(--width-nla) + 1rem);
  transition: all 0.15s ease-in-out;
}

.mdl-layout__header .mdl-layout__drawer-button {
  margin-left: 31px !important;
}

.fes,
.fcc {
  display: flex;
  justify-content: flex-end;
  align-content: flex-start;
  width: 100%;
}

.fcc {
  justify-content: center;
  align-items: center;
}

.fes>a {
  display: inline-block;
  margin: 0 20px;
  color: var(--secundario);
}

.fes>a:disabled {
  color: var(--texto) !important;
  opacity: .45;
  cursor: not-allowed;
}

.fcc a.disabled {
  color: var(--texto) !important;
  opacity: .45;
  cursor: not-allowed;
}

.fes a:last-child {
  margin: 0;
}

.color-primario {
  color: var(--primario) !important;
}

.indicator {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: max-content;
  padding: 1rem;
}

.indicator__icon {
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem !important;
  width: 54px;
  height: 54px;
  background: #2c2633;
  border-radius: 50%;
  color: white;
  padding: 0;
}

.indicator__info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: max-content;
  text-align: right;
}

.indicator__info .data {
  width: calc(100% - 3.5rem);
}

.indicator__info p {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  color: var(--texto);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.indicator__info .title {
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  opacity: 0.75;
  user-select: none;
}

.indicator__info .title._link {
  text-decoration: underline;
  color: var(--secundario);
  cursor: pointer;
}

.indicator__info .cantidad {
  font-size: 25px;
  font-weight: bold;
}

.indicator__grafica {
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 50%;
}

.indicator__grafica .grafica {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  z-index: 1;
}

.indicator__grafica--legend {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: max-content;
  margin: 1rem 0 0;
}

.indicator__grafica--legend p {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: max-content;
  margin: 0 0.25rem;
  padding: 0;
  color: var(--texto);
  font-size: 0.75rem;
  user-select: none;
}

.indicator__grafica--legend p .circle {
  display: block;
  width: 10px;
  height: 10px;
  background: var(--fondo);
  border-radius: 50%;
  margin-right: 0.25rem;
}

.card-group {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: max-content;
  padding: 3rem 1rem 1rem;
  background: var(--barra);
  border-radius: 5px;
  user-select: none;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.card-group .card-group__fecha {
  position: absolute;
  top: 0.05rem;
  right: 0.25rem;
  display: block;
  width: max-content;
  height: max-content;
  padding: 0.25rem;
  font-size: 0.75rem;
  text-transform: capitalize;
  color: var(--texto);
  opacity: 0.85;
}

.card-group .card-group__users,
.card-group .card-group__ptct {
  position: absolute;
  top: 4px;
  left: 2px;
  display: block;
  width: max-content;
  height: max-content;
  padding: 0.25rem;
  font-size: 1rem;
  color: var(--texto);
  opacity: 0.85;
}

.card-group .card-group__ptct {
  top: -2px;
  left: 50%;
  transform: translate(-50%, 0);
  background: rgba(0, 0, 0, 0.05);
  color: var(--primario);
  font-size: 0.85rem;
  font-weight: bold;
  padding: 0 1rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.card-group .card-group__title {
  display: block;
  width: 100%;
  min-height: initial;
  height: max-content;
  line-height: 1.2;
  font-size: 1.35rem;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--texto);
  opacity: 0.85;
  margin: 0;
  margin-top: 8px;
  padding: 0 1rem;
  text-align: center;
}

.card-group .card-group__graph {
  position: relative;
  display: block;
  width: 100%;
  margin-top: .75rem;
  height: max-content;
}

.card-group .card-group__graph .title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1rem;
  font-weight: normal;
  text-align: center;
  color: var(--texto);
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: .5rem 0 0;
}

.card-group .card-group__graph .title .icon-info {
  display: block;
  margin: 0 0 0 .5rem;
  padding: 0;
  cursor: pointer;
}

.tooltip-dark .az-tooltip {
  --bg: var(--dark) !important;
  font-family: "Muli", sans-serif !important;
  padding: 0.5rem !important;
}

.tooltip-dark .az-tooltip p.item {
  max-width: 250px !important;
  text-align: center !important;
  font-size: 0.85rem !important;
  white-space: initial !important;
}

.tooltip-dark .az-tooltip.top::before {
  border-top: 12px solid var(--dark) !important;
}

.card-group .card-group__graph .title.title--link {
  cursor: pointer;
}

.card-group .card-group__graph .graph-progress {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: calc(100%);
  margin: 0;
  height: 30px;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
}

.card-group .card-group__graph .graph-progress .load {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--p-width);
  height: 100%;
  background: #777777;
  margin: 0;
  padding: 0;
}

.card-group .card-group__graph .graph-progress .load.c-49 {
  background: #e83926;
}

.card-group .card-group__graph .graph-progress .load.c-50-75 {
  background: #e6cb0f;
}

.card-group .card-group__graph .graph-progress .load.c-76 {
  background: #06993a;
}

.card-group .card-group__graph .graph-progress .load span {
  font-size: 0.85rem;
  color: #ffffff;
}

.card-group .card-group__graph .graph-progress .load span.text--center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 100%;
  text-align: center;
  color: var(--texto);
  opacity: 0.85;
}

.card-group .card-group__graph.bbr {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.card-group .card-group__graph .graph-circle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  background: var(--barra);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 0.5rem;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card-group .card-group__graph .graph-circle>.item {
  flex-direction: column;
  position: relative;
  width: calc((100% / 3));
  height: max-content;
  padding: 0.5rem;
  background: var(--barra);
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.card-group .card-group__graph .graph-circle>.item:first-child {
  border: none;
  border-bottom-left-radius: 8px;
}

.card-group .card-group__graph .graph-circle>.item:last-child {
  border-bottom-right-radius: 8px;
}

.card-group .card-group__graph .graph-circle>.item.c-49 {
  color: #e83926;
}

.card-group .card-group__graph .graph-circle>.item.c-50-75 {
  color: #e6cb0f;
}

.card-group .card-group__graph .graph-circle>.item.c-76 {
  color: #06993a;
}

.card-group .card-group__graph .graph-circle>.item.w50 {
  width: 50%;
}

.card-group .card-group__graph .graph-circle>.item.w40 {
  width: 40%;
}

.card-group .card-group__graph .graph-circle>.item.w30 {
  width: 30%;
}

.card-group .card-group__graph .graph-circle>.item.w100 {
  width: 100%;
}

.card-group .card-group__graph .graph-circle>.item p {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 0.85rem;
  color: inherit;
  padding: 0;
  margin: 0;
}

.card-group .card-group__graph .graph-circle>.item p:first-child {
  font-size: 1.05rem;
  font-weight: bold;
}

.button-app.button-app--icon {
  border: none;
  box-shadow: none;
  outline: none;
  background: var(--secundario);
  color: var(--texto-boton);
  padding: 0.5rem 0.75rem;
  width: max-content;
  margin: 0.5rem auto 0;
  border-radius: 8px;
  font-size: 1.15rem;
}

.mdl-button.secundario-color {
  color: var(--secundario);
  background: transparent;
}

.card-group .card-group__graph .graph-circle>.item .button-app span {
  color: inherit;
  font-size: inherit;
  padding: 0;
  margin: 0;
  display: block;
}

.card-group .card-group__graph .graph-circle>.item p>span {
  display: inline-block !important;
  width: max-content !important;
  font-size: inherit !important;
  color: inherit !important;
}

.card-group .card-group__buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: .75rem;
}

.card-group .card-group__buttons button {
  width: calc(100% / 2);
}

.wrapper-table-app {
  --width-media-grid: calc((100% / 3) - 1rem);
  --margin-media-grid: 1rem;
  position: relative;
  margin: 0;
  padding: 0 1rem;
  width: 100%;
  height: max-content;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--width-media-grid), 1fr));
  grid-gap: var(--margin-media-grid);
  margin-bottom: 1rem;
}

.wrapper-table-app .mytable {
  display: block;
  width: 100%;
  height: max-content;
  border-radius: 4px;
  overflow: hidden;
}

.wrapper-table-app .mytable .mytable--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: max-content;
  padding: 0.5rem;
  background: #999999;
}

.wrapper-table-app .mytable .mytable--header.c49 {
  background: #e83926;
}

.wrapper-table-app .mytable .mytable--header.c50-75 {
  background: #e6cb0f;
}

.wrapper-table-app .mytable .mytable--header.c76 {
  background: #06993a;
}

.wrapper-table-app .mytable .mytable--header .text {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 0.85rem;
  color: #ffffff;
}

.wrapper-table-app .mytable .mytable--header .buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: max-content;
  height: max-content;
}

.wrapper-table-app .mytable .mytable--header .buttons .btn-app {
  display: block;
  width: max-content;
  height: max-content;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.85);
  color: #ffffff;
  padding: 0.1rem 0.5rem;
  font-size: 0.95rem;
  border-radius: 5px;
  margin-left: 0.25rem;
  outline: none !important;
  box-shadow: none !important;
  cursor: pointer;
}

.wrapper-table-app .mytable .mytable--data {
  width: 100%;
  height: max-content;
  max-height: calc(100vh - 246px);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow-y: auto;
  overflow-x: hidden;
}

.wrapper-table-app .mytable .mytable--data .item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--barra);
  padding: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.wrapper-table-app .mytable .mytable--data .item:last-child {
  border: none;
}

.wrapper-table-app .mytable .mytable--data .item.item--titles {
  background: var(--fondo);
  border: none;
}

.wrapper-table-app .mytable .mytable--data .item .th,
.wrapper-table-app .mytable .mytable--data .item .td {
  width: calc(100% - 36%);
  color: var(--texto);
  margin: 0;
  padding: 0.25rem 0.5rem;
  font-size: 0.85rem;
  text-align: left;
}

.wrapper-table-app .mytable .mytable--data .item .th.th-clic {
  cursor: pointer;
}

.wrapper-table-app .mytable .mytable--data .item .th:last-child,
.wrapper-table-app .mytable .mytable--data .item .td:last-child {
  width: 36%;
  text-align: right;
}

.wrapper-table-app .mytable .mytable--data .item .td {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.wrapper-table-app .mytable .mytable--data .item .td.td--link {
  color: var(--secundario);
  text-decoration: underline;
  cursor: pointer;
}

.wrapper-table-app .mytable .mytable--header .buttons .btn-app span {
  padding: 0;
}

@media only screen and (max-width: 766px) {
  .wrapper-table-app {
    --width-media-grid: calc((100%) - 1rem);
  }
}

a.mat-mdc-tab-link.mat-mdc-focus-indicator.mat-mdc-tab-label-active {
  color: var(--primario) !important;
  font-weight: bold;
  opacity: 1;
}

a:hover {
  color: var(--secundario);
}

a.mat-mdc-tab-link {
  text-decoration: none !important;
  color: var(--texto) !important;
  opacity: 0.65;
}

.my-grid-app {
  --width-media-grid: calc(100% / 12);
  --margin-media-grid: 0;
  display: grid;
  width: 100%;
  height: max-content;
  grid-template-columns: repeat(auto-fit,
      minmax(var(--width-media-grid), var(--width-media-grid)));
  grid-gap: var(--margin-media-grid);
}

.my-grid-app--cell {
  padding: 0 0.5rem;
}

.my-grid-app--cell:first-child {
  padding-left: 1rem;
}

.my-grid-app--cell:last-child {
  padding-right: 1rem;
}

.my-grid-app--cell .my-grid-app--cell {
  padding: 0;
}

.my-grid-app--cell.my-grid-app--cell-1 {
  grid-column: span 1;
}

.my-grid-app--cell.my-grid-app--cell-2 {
  grid-column: span 2;
}

.my-grid-app--cell.my-grid-app--cell-3 {
  grid-column: span 3;
}

.my-grid-app--cell.my-grid-app--cell-4 {
  grid-column: span 4;
}

.my-grid-app--cell.my-grid-app--cell-5 {
  grid-column: span 5;
}

.my-grid-app--cell.my-grid-app--cell-6 {
  grid-column: span 6;
}

.my-grid-app--cell.my-grid-app--cell-7 {
  grid-column: span 7;
}

.my-grid-app--cell.my-grid-app--cell-8 {
  grid-column: span 8;
}

.my-grid-app--cell.my-grid-app--cell-9 {
  grid-column: span 9;
}

.my-grid-app--cell.my-grid-app--cell-10 {
  grid-column: span 10;
}

.my-grid-app--cell.my-grid-app--cell-11 {
  grid-column: span 11;
}

.my-grid-app--cell.my-grid-app--cell-12 {
  grid-column: span 12;
}

@media only screen and (max-width: 992px) {
  .my-grid-app--cell {
    padding: 0 1rem;
  }

  .wrapper-title-app {
    padding: 10px;
  }

  .wrapper-title-app .fecha-app {
    right: 10px;
  }

  .my-grid-app--cell.my-grid-app--cell-1-md {
    grid-column: span 1;
  }

  .my-grid-app--cell.my-grid-app--cell-2-md {
    grid-column: span 2;
  }

  .my-grid-app--cell.my-grid-app--cell-3-md {
    grid-column: span 3;
  }

  .my-grid-app--cell.my-grid-app--cell-4-md {
    grid-column: span 4;
  }

  .my-grid-app--cell.my-grid-app--cell-5-md {
    grid-column: span 5;
  }

  .my-grid-app--cell.my-grid-app--cell-6-md {
    grid-column: span 6;
  }

  .my-grid-app--cell.my-grid-app--cell-7-md {
    grid-column: span 7;
  }

  .my-grid-app--cell.my-grid-app--cell-8-md {
    grid-column: span 8;
  }

  .my-grid-app--cell.my-grid-app--cell-9-md {
    grid-column: span 9;
  }

  .my-grid-app--cell.my-grid-app--cell-10-md {
    grid-column: span 10;
  }

  .my-grid-app--cell.my-grid-app--cell-11-md {
    grid-column: span 11;
  }

  .my-grid-app--cell.my-grid-app--cell-12-md {
    grid-column: span 12;
  }
}

@media only screen and (max-width: 599px) {
  .my-grid-app--cell.my-grid-app--cell-1-sm {
    grid-column: span 1;
  }

  .my-grid-app--cell.my-grid-app--cell-2-sm {
    grid-column: span 2;
  }

  .my-grid-app--cell.my-grid-app--cell-3-sm {
    grid-column: span 3;
  }

  .my-grid-app--cell.my-grid-app--cell-4-sm {
    grid-column: span 4;
  }

  .my-grid-app--cell.my-grid-app--cell-5-sm {
    grid-column: span 5;
  }

  .my-grid-app--cell.my-grid-app--cell-6-sm {
    grid-column: span 6;
  }

  .my-grid-app--cell.my-grid-app--cell-7-sm {
    grid-column: span 7;
  }

  .my-grid-app--cell.my-grid-app--cell-8-sm {
    grid-column: span 8;
  }

  .my-grid-app--cell.my-grid-app--cell-9-sm {
    grid-column: span 9;
  }

  .my-grid-app--cell.my-grid-app--cell-10-sm {
    grid-column: span 10;
  }

  .my-grid-app--cell.my-grid-app--cell-11-sm {
    grid-column: span 11;
  }

  .my-grid-app--cell.my-grid-app--cell-12-sm {
    grid-column: span 12;
  }

}

.mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-prefix .mat-mdc-datepicker-toggle-default-icon,
.mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-suffix .mat-mdc-datepicker-toggle-default-icon,
.mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-prefix .mat-mdc-icon-button .mat-mdc-icon,
.mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-suffix .mat-mdc-icon-button .mat-mdc-icon {
  color: var(--texto) !important;
  opacity: .85 !important;
}

.mat-mdc-slide-toggle.mat-mdc-checked .mat-mdc-slide-toggle-bar {
  background-color: rgb(130, 127, 127, .72);
}

.mat-mdc-slide-toggle.mat-mdc-checked .mat-mdc-slide-toggle-thumb {
  background-color: rgba(130, 127, 127, .9);
}

.mat-mdc-calendar .mat-mdc-button,
.mat-mdc-calendar .mat-mdc-icon-button,
.mat-mdc-calendar .mat-mdc-stroked-button,
.mat-mdc-calendar .mat-mdc-flat-button {
  color: var(--texto) !important;
}

.mat-mdc-calendar .mat-mdc-calendar-body-cell-content.mat-mdc-calendar-body-selected {
  color: var(--texto-boton) !important;
}

.gjs-cv-canvas {
  width: 78%;
}

.gjs-pn-views-container,
.gjs-pn-views {
  width: 22%;
}

.cdk-overlay-pane.modal-full-screen.modal-chat {
  width: max-content;
  max-width: calc(90% - 2rem);
  min-height: calc(90% - 2rem);
  height: calc(90% - 2rem);
  justify-content: center;
}

.cdk-overlay-pane.modal-meta {
  width: 700px;
  max-height: calc(90% - 2rem);
  overflow-y: auto;
}

.cdk-overlay-pane.paneGlicosilada {
  max-height: calc(100% - 2rem);
}

.cdk-overlay-pane.modal-full-screen.modal-chat .mat-mdc-dialog-container {
  width: max-content !important;
  height: max-content !important;
  min-width: initial;
  max-width: initial;
  min-height: initial;
  max-height: initial;
}

.cdk-overlay-pane.modal-full-screen.chart-buscamina-modal .mat-mdc-dialog-container .mdc-dialog__surface,
.cdk-overlay-pane.modal-full-screen.modal-chat .mat-mdc-dialog-container .mdc-dialog__surface,
.cdk-overlay-pane.modal-history-meditions .mat-mdc-dialog-container .mdc-dialog__surface,
.cdk-overlay-pane.searchBeneficiarioAddPrograma .mat-mdc-dialog-surface.mdc-dialog__surface,
.cdk-overlay-pane.paneGlicosilada .mat-mdc-dialog-surface.mdc-dialog__surface {
  padding: 1rem;
  overflow: hidden;
}

.cdk-overlay-pane.modal-full-screen.modal-chat .mat-mdc-dialog-container .mat-mdc-dialog-content {
  margin: 0;
  padding: 0;
  max-height: 100%;
  overflow: hidden;
}

.chat-app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 570px;
  max-width: 100%;
  height: 72vh;
}

.chat-app--msg {
  position: relative;
  display: block;
  width: 100%;
  height: calc(100% - (140px + .5rem));
  background: rgba(0, 0, 0, .02);
  border: 1px solid rgba(0, 0, 0, .1);
  padding: 0 .5rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.chat-app--msg::-webkit-scrollbar {
  background: transparent;
  width: 8px;
}

.chat-app--msg::-webkit-scrollbar-thumb {
  background: var(--primario);
  border: 2px solid var(--barra);
  border-radius: 20px;
}

.chat-app--msg .msg {
  position: relative;
  width: max-content;
  min-width: 160px;
  max-width: 80%;
  height: max-content;
  border-radius: 5px;
  padding: .5rem .5rem .2rem;
}

.chat-app--msg .msg p {
  width: 100%;
  color: inherit;
  margin: 0;
}

.chat-app--msg .msg p:first-child {
  margin-bottom: .5rem;
}

.chat-app--msg .msg .title {
  font-size: 1rem;
  font-weight: bold;
  opacity: .95;
}

.chat-app--msg .msg .descp {
  font-size: .85rem;
  opacity: .85;
  font-weight: normal;
}

.chat-app--msg .msg .fecha {
  font-size: .6rem;
  font-weight: bold;
  margin-top: .5rem;
  opacity: .85;
}

.chat-app--msg .msg.left {
  margin: 1rem auto 1rem 15px;
  background: var(--fondo);
  color: var(--texto);
}

.chat-app--msg .msg.right {
  margin: 1rem 15px 1rem auto;
  background: var(--primario);
  color: var(--texto-boton);
}

.chat-app--msg .msg.left .fecha {
  text-align: right;
}

.chat-app--msg .msg.right .fecha {
  text-align: left;
}

.chat-app--msg .msg .icon-readed {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  font-size: .95rem;
  color: var(--terciario);
}

.chat-app--msg .msg.left::before {
  content: "";
  position: absolute;
  top: .5rem;
  left: -15px;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-right: 15px solid var(--fondo);
  border-bottom: 15px solid transparent;
}

.chat-app--msg .msg.right::before {
  content: "";
  position: absolute;
  top: .5rem;
  right: -15px;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-left: 15px solid var(--primario);
  border-bottom: 15px solid transparent;
}

.chat-app--input {
  position: relative;
  width: 100%;
  height: 140px;
}

.chat-app--input .input-title,
.chat-app--input .input-mensaje {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.chat-app--input .chat-input {
  display: block;
  width: 100%;
  margin-bottom: .5rem;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, .29);
  border-radius: 5px;
  padding: .5rem;
  color: var(--texto);
  background: var(--barra);
}

.chat-app--input .input-mensaje .chat-input {
  width: calc(100% - (40px + .5rem));
  min-height: calc(140px - (40px + .5rem));
  max-height: calc(140px - (40px + .5rem));
  margin-bottom: 0;
  resize: none;
}

.chat-app--input .chat-btn {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40px;
  height: calc(140px - (40px + .5rem));
  line-height: calc(140px - (40px + .5rem));
  text-align: center;
  border: none;
  border-radius: 5px;
  color: var(--texto-boton);
  background: var(--secundario);
  font-size: 1.2rem;
  cursor: pointer;
}

.chat-app--input .chat-btn .icon-send {
  transform: rotate(45deg);
}

.chat-app--input .chat-btn:disabled {
  color: rgba(0, 0, 0, 0.3);
  background: rgba(0, 0, 0, 0.15);
  cursor: not-allowed;
}

.chat-app--msg .info {
  width: 100%;
  height: max-content;
  padding: .5rem;
  margin: .5rem 0 1rem;
  background: rgba(255, 245, 196, .95);
  border-radius: 5px;
}

.chat-app--msg .info p {
  display: block;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: .75rem;
  color: var(--texto);
  opacity: .85;
  line-height: 1.3;
  letter-spacing: .05rem;
}

@media only screen and (max-width: 499px) {
  .header-title {
    display: none !important;
  }
}

.toggle-dark-mode {
  position: relative;
  display: block;
  width: 35px;
  height: 20px;
  border-radius: 50px;
  background: rgba(148, 148, 148, 0.29);
  margin-right: 1rem;
  cursor: pointer;
}

.toggle-dark-mode span.fa {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  line-height: 28px;
  font-size: .95rem;
  background: var(--primario);
  border-radius: 50px;
  padding: 0 !important;
  top: 50%;
  left: calc(-35px / 2);
  transform: translate(0, -50%);
  color: var(--texto-boton);
  transition: all .25s ease-in-out;
}

.toggle-dark-mode.active {
  margin-right: 1.5rem;
}

.toggle-dark-mode.active span.fa {
  left: 62%;
}

.mat-mdc-button.mat-mdc-primary[disabled],
.mat-mdc-button.mat-mdc-accent[disabled],
.mat-mdc-button.mat-mdc-warn[disabled],
.mat-mdc-button[disabled][disabled],
.mat-mdc-icon-button.mat-mdc-primary[disabled],
.mat-mdc-icon-button.mat-mdc-accent[disabled],
.mat-mdc-icon-button.mat-mdc-warn[disabled],
.mat-mdc-icon-button[disabled][disabled],
.mat-mdc-stroked-button.mat-mdc-primary[disabled],
.mat-mdc-stroked-button.mat-mdc-accent[disabled],
.mat-mdc-stroked-button.mat-mdc-warn[disabled],
.mat-mdc-stroked-button[disabled][disabled] {
  opacity: .5 !important;
}

.mat-mdc-paginator-icon {
  fill: inherit !important;
}

.button {
  display: inline-block;
  font-weight: 400;
  color: var(--primario);
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: var(--barra);
  border: 1px solid var(--secundario);
  padding: 0.438rem 1.125rem;
  font-size: 1rem;
  line-height: 1.54;
  border-radius: 0.25rem;
  transition: all 0.15s ease-in-out;
  margin: 0 0.5rem;
  outline: none !important;
  text-transform: capitalize !important;
}

.button span {
  padding: 0 !important;
}

.button--primario {
  border-color: transparent;
  background: var(--primario);
  color: var(--texto-boton);
}

.button--success {
  border-color: transparent;
  background: var(--success-color);
  color: var(--texto-boton);
}

.button:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

.button:disabled {
  background: rgba(0, 0, 0, 0.15) !important;
  color: var(--grisclaro) !important;
  border-color: transparent !important;
  cursor: not-allowed;

}

.button:disabled:hover {
  background: rgba(0, 0, 0, 0.15) !important;
}

.card.custom {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: var(--barra);
  background-clip: border-box;
  border: 1px solid rgba(24, 28, 33, 0.06) !important;
  border-radius: 0.25rem !important;
  margin-bottom: 1rem;
}

.card.custom .card-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .45rem .65rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 1px solid rgba(24, 28, 33, 0.06);
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card.custom .card-header .icon-close {
  position: absolute;
  top: 50%;
  right: 1.35rem;
  transform: translate(0, -50%);
  font-size: 1.8rem;
  color: var(--primario);
  cursor: pointer;
}

.card.custom .card-header .tools {
  margin: 0;
  border: none;
}

.form-control {
  margin-right: 0.5rem;
}

.card.custom .card-header .tools__item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 35px;
  border-radius: 0.25rem;
  padding: 0;
  margin: 0 0.25rem;
  border: 1px solid var(--secundario);
}

.card.custom .card-header .tools__item:last-child {
  margin-right: 0;
}

.card.custom .card-header .tools__item:hover {
  background: rgba(0, 0, 0, 0.15);
}

.card.custom .card-dividir {
  width: 100%;
}

.mdl-data-table.tabla-paciente-vigentes td:first-of-type,
.mdl-data-table th:first-of-type {
  width: 200px !important;
}

.inputAddFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: max-content;
}

.inputAddFlex .icon-plus {
  color: var(--primario);
  font-size: 1.5rem;
  padding: 0;
  margin-left: 1rem;
  cursor: pointer;
}

.inputAddFlex .icon-plus.disabled {
  color: rgba(0, 0, 0, 0.35);
}

.dataTables_length select {
  padding: 10px;
  background-color: var(--barra) !important;
  color: var(--texto) !important;
  margin: 0 0.75rem 0.5rem !important;
  height: max-content !important;
  border-bottom: 1px solid rgba(0, 0, 0, .35) !important;
  border-radius: 0 !important;
}

.mat-mdc-menu-panel .mat-mdc-menu-content button.mat-mdc-focus-indicator.mat-mdc-menu-item {
  color: var(--texto) !important;
}

label {
  color: var(--texto) !important;
}

.selected-list .c-btn span {
  color: var(--texto-boton) !important;
}

.selected-list .c-angle-down,
.selected-list .c-angle-up {
  display: flex;
  justify-content: center;
  align-items: center;
}

.list-area {
  background: var(--fondo) !important;
  border-color: rgba(0, 0, 0, 0.15) !important;
}

.list-filter {
  border-color: rgba(0, 0, 0, 0.15) !important;
}

.list-filter input {
  background: transparent !important;
  color: var(--texto) !important;
}

.list-filter .c-search {
  top: 34% !important;
  transform: translate(0, -50%) !important;
}

.dropdown-list .arrow-down,
.dropdown-list .arrow-up {
  display: none !important;
}

.card-group {
  background: var(--barra) !important;
}

.card-group .card-group__graph .graph-progress {
  background: rgba(0, 0, 0, 0.05) !important;
}

.msg-alert-app {
  display: block;
  width: 100%;
  height: max-content;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 4px;
  color: var(--texto);
  background-color: rgba(0, 0, 0, 0.065);
  border: 1px solid rgba(160, 99, 99, 0.065);
  opacity: .8;
  user-select: none;
}

.tag-mensaje {
  background: rgba(0, 0, 0, 0.05) !important;
  color: var(--texto) !important;
}

.tooltip .tooltiptext {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  background: var(--texto) !important;
  color: var(--barra) !important;
}

.tooltip .tooltiptext i {
  margin-left: .45rem !important;
}

.mat-mdc-dialog-container .dropdown-list.tagToBody {
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;
}

.mat-mdc-calendar-body-disabled .mat-mdc-calendar-body-cell-content {
  color: rgba(0, 0, 0, .45) !important;
}

.cdk-overlay-pane.searchBeneficiarioAddPrograma {
  max-width: 95vw !important;
}

.w30px {
  width: 30px !important;
}

.w4porcentaje {
  width: 4% !important;
}

.w16porcentaje {
  width: 16% !important;
}

.not-sortabled::before,
.not-sortabled::after {
  display: none !important;
}

.wCheck {
  width: 80px !important;
}

.wNormalTablaPV {
  width: calc(100% * 10 - 80px) !important;
}

.form-label {
  display: block;
  width: 100%;
  text-align: left;
  font-size: .75rem;
  color: var(--texto) !important;
  margin: 0 0 1px !important;
}

.form-control {
  border-bottom: 1px solid var(--texto) !important;
  border-radius: 0 !important;
  padding: .05rem 0 .35rem !important;
  height: max-content !important;
}

.form-control:focus {
  border-bottom-color: var(--primario) !important;
}

.mat-mdc-dialog-content {
  overflow-x: hidden !important;
}

.tools {
  position: relative;
  width: max-content;
  height: 100%;
  margin: 0 auto;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tools__item {
  position: relative;
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  font-size: 0.75rem;
  line-height: 1.5;
  font-size: var(--fs-text);
  background: var(--white-color);
  color: var(--secundario);
  border: none;
  padding: 0;
  border-radius: 50px !important;
  cursor: pointer;
}

.tools__item.badge::after {
  content: attr(data-badge);
  position: absolute;
  top: 3px;
  right: -5px;
  font-family: "Muli", sans-serif;
  font-size: .5rem;
  width: max-content;
  height: 12px;
  text-align: center;
  line-height: 11px;
  background: var(--error-color);
  border-radius: 50px;
  color: #ffffff;
  margin: 0;
  padding: 2px 0.25rem;
  user-select: none;
}

.tools__item.no-default {
  color: rgba(0, 0, 0, 0.15);
}

.tools__item.ion-md-add {
  background: var(--primario);
  border-color: var(--primario) !important;
  color: var(--white-color);
}

.tools__item.ion-md-add:hover {
  background: var(--primario) !important;
}

.tools__item:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-left-width: 1px;
}

.tools__item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.tools__item:hover {
  background: rgba(0, 0, 0, .075);
}

.ngx-pagination .current {
  background: var(--primario) !important;
}

.cdk-overlay-pane.modal-full-screen .mat-mdc-dialog-content {
  min-height: calc(100% - 65px) !important;
}

.no-display {
  display: none !important;
}

.template-default {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  font-size: .75rem;
  background: rgba(0, 0, 0, .25);
  color: rgba(0, 0, 0, .25);
  border-radius: 50px;
  margin-top: 5px;
  padding: 0;
}

.template-default.active {
  background: var(--secundario);
  color: var(--secundario);
}

.button-send-image {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.button-send-image p {
  color: var(--texto);
  margin: 0;
  margin-left: .5rem;
}

.toggle-app {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 18px;
  border-radius: 50px;
  background: rgba(148, 148, 148, 0.29);
  cursor: pointer;
}

.toggle-app__circle {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  width: 22px;
  height: 22px;
  border-radius: 50px;
  background: var(--primario);
  transition: all .15s ease-in-out;
  z-index: 2;
}

.toggle-app.checked .toggle-app__circle {
  left: calc(100% - 22px);
}

.mat-mdc-select-disabled .mat-mdc-select-value,
.form-control:disabled {
  color: rgba(0, 0, 0, .38) !important;
}

.wrapper-no-access {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
}

.wrapper-no-access .img-no-access {
  display: block;
  width: 200px;
  height: auto;
  padding: 0;
  margin: 0 0 2.5rem;
  object-fit: contain;
  object-position: center center;
}

.wrapper-no-access .title-app {
  font-size: 1.85rem;
  color: var(--texto);
  margin: 0 0 1.5rem;
}

.wrapper-no-access .parrafo-app {
  font-size: 1.15rem;
  color: var(--texto);
  padding: 0;
  margin: 0;
}

[az-tooltip] {
  position: relative !important;
}

[az-tooltip]:hover .az-tooltip {
  display: block !important;
}

.az-skeleton {
  position: relative;
  width: calc(100% - 1rem) !important;
  font-size: 0 !important;
  background-color: #dee2e6 !important;
  border-radius: 6px;
  margin: 0.5rem auto !important;
  overflow: hidden;
}

.az-skeleton.az-skeleton-x-2 {
  width: calc((100% / 2) - 1rem) !important;
}

.az-skeleton.az-skeleton-x-3 {
  width: calc((100% / 3) - 1rem) !important;
}

.az-skeleton.mh-48 {
  min-height: 48px;
}

.az-skeleton.mh-24 {
  min-height: 24px;
}

.az-skeleton>* {
  display: none !important;
}

.az-skeleton::after {
  content: "";
  animation: skeleton-animation 1.2s infinite;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
  transform: translate(-100%);
  z-index: 1;
}

@keyframes skeleton-animation {
  from {
    transform: translate(-100%);
  }

  to {
    transform: translate(100%);
  }
}

.border-none {
  border: none !important;
}

.shadow-none {
  box-shadow: none !important;
}

.filter-adherencia {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: max-content;
}

.filter-adherencia .title-app {
  font-size: 1rem;
  display: block;
  width: 100%;
  margin: 0 0 0.5rem;
  padding: 0.5rem 0;
  color: var(--texto);
}

.filter-adherencia__items {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.filter-adherencia__items .item {
  --c: var(--grisclaro);
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  border-radius: 50px;
  margin: 0 0.5rem;
  padding: 0.35rem 0.75rem;
  border: 1px solid var(--c);
  cursor: pointer;
}

.filter-adherencia__items button {
  margin: 0 .5rem;
}

.filter-adherencia__items .item:first-child {
  margin-left: 0;
}

.filter-adherencia__items .item:last-child {
  margin-right: 0;
}

.filter-adherencia__items .item .title {
  color: var(--c);
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
  user-select: none;
}

.filter-adherencia__items .item.active {
  background: var(--c);
}

.filter-adherencia__items .item.active {
  background: var(--c);
}

.filter-adherencia__items .item.black {
  --c: #000000ad;
}

.filter-adherencia__items .item.normal {
  --c: #69b09f;
}

.filter-adherencia__items .item.leve {
  --c: #e3c19f;
}

.filter-adherencia__items .item.moderado {
  --c: #e16f46;
}

.filter-adherencia__items .item.severo {
  --c: #d65959;
}

.filter-adherencia__items .item.active .title {
  color: var(--white);
}

.filter-adherencia__items .item.c-49 {
  --c: #e83926;
}

.filter-adherencia__items .item.c-50-75 {
  --c: #e6cb0f;
}

.filter-adherencia__items .item.c-76 {
  --c: #06993a;
}

.flex {
  width: 100%;
  display: flex;
}

.flex.jf-start {
  justify-content: flex-start;
}

.flex.ai-start {
  align-items: flex-start;
}

.flex.ai-center {
  align-items: center;
}

.circulo-table {
  display: block;
  width: 10px;
  height: 10px;
  padding: 0;
  margin-top: 8px;
  margin-right: 0.35rem;
  border-radius: 50px;
  background: var(--grisclaro);
}

.circulo-table.c49 {
  background: #e83926;
}

.circulo-table.c50_75 {
  background: #e6cb0f;
}

.circulo-table.c76 {
  background: #06993a;
}

.table-link.especial {
  width: calc(100% - (10px + .35rem));
}

.graph-wrapper {
  position: relative;
  display: block;
  width: 100%;
  height: max-content;
  padding: 0;
  margin: 0 0 1rem;
}

.graph-wrapper:last-child {
  margin: 0;
}

.graph-wrapper--legend {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: max-content;
  padding: 1rem;
  margin: 0;
}

.graph-wrapper--title {
  display: block;
  width: 100%;
  padding: 0 0 .5rem;
  margin: 0 0 .5rem;
  border-bottom: 1px solid rgba(0, 0, 0, .065);
}

.graph-wrapper--legend .label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: max-content;
  height: max-content;
  margin: .25rem 0;
  font-size: .85rem;
  margin-right: .75rem;
  color: var(--texto);
  user-select: none;
}

.graph-wrapper--legend .label .circle-color {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  background: var(--texto);
  margin-right: .25rem;
}

.graph-wrapper--legend .label:last-child {
  margin-right: 0;
}

.color-excelente {
  background: #03881D !important;
}

.color-normal {
  background: #0C9E6A !important;
}

.color-elevada {
  background: #DBBF20 !important;
}

.color-hipertesion-etapa-1 {
  background: #F0720C !important;
}

.color-hipertesion-etapa-2 {
  background: #BE1D1E !important;
}

.color-crisis-hipertensiva {
  background: #8B1A17 !important;
}

.graph-wrapper--graph {
  position: relative;
  display: block;
  width: 100%;
  height: max-content;
  padding: 0;
  margin: 0;
}

.graph-wrapper--graph .graph {
  width: 100%;
  height: auto;
}

.tag-program-beneficiary {
  background: var(--primario);
  color: #fff;
  font-weight: bold;
  display: inline-block;
  border-radius: 50%;
  font-size: 15px;
  width: 25px;
  height: 24px;
  margin: 0.08rem;
  cursor: pointer;
  text-transform: uppercase;
  padding-left: 0.3rem;
  text-align: center;
}

.tag-program-see-more {
  display: inline-block;
  width: 25px;
  height: 24px;
  color: var(--secundario);
  font-size: 15px;
  position: relative;
  top: -0.2rem;
  zoom: 1.5;
  cursor: pointer;
}

.tag-program-beneficiary-gestion {
  background: var(--primario);
  color: #fff;
  font-weight: bold;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 15px;
  width: 30px;
  height: 30px;
  max-width: 30px;
  margin: 0.02rem;
  cursor: pointer;
  text-transform: uppercase;
  padding: 0;
  text-align: center;
}

.tag-program-beneficiary-gestion .fa,
.tag-program-beneficiary-gestion .fas {
  margin: 0 !important;
  padding: 0 !important;
}

.tag-program-beneficiary-gestion.sleep {
  background: var(--secundario);
}

.tag-adherencia-gestion {
  background: var(--primario);
  color: #fff;
  font-weight: bold;
  display: inline-block;
  border-radius: 50%;
  font-size: 9px;
  width: 30px;
  height: 30px;
  margin: 0.1rem;
  cursor: pointer;
  text-transform: uppercase;
  padding-top: 0.5rem;
  text-align: center;
  white-space: nowrap;
}

.semaforo {
  width: 10px;
  height: 10px;

  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
}

.semaforo.red,
.semaforo.severo {
  background: #d65959;
}

.semaforo.orange,
.semaforo.moderado {
  background: #e16f46;
}

.semaforo.yellow,
.semaforo.leve {
  background: #e3c19f;
}

.semaforo.green,
.semaforo.normal {
  background: #69b09f;
}

.evaluation span:first-child {
  font-weight: bold;
  font-size: 13px;
}

.evaluation span:last-child {
  font-size: 12px;
}

.fz-10 {
  font-size: 10px;
}

.fz-12 {
  font-size: 12px;
}

.fz-14 {
  font-size: 14px;
}

.fz-24 {
  font-size: 24px;
}

@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 100% !important;
  }
}

.table {
  color: var(--texto) !important;
}

.cdk-global-scrollblock {
  overflow: hidden !important;
}

.invalid-feedback {
  font-size: .9rem !important;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding: 10px 0 0 !important;
  max-height: max-content;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-infix .mat-mdc-floating-label {
  margin: 0;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 38px !important;
}

.mdc-text-field--filled .mdc-floating-label--float-above {
  transform: translateY(-176%) scale(0.76) !important;
}

.mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
  transform: translateY(2px) !important;
}

.mdc-text-field--disabled.mdc-text-field--filled {
  background-color: transparent !important;
}

.mdc-dialog .mdc-dialog__content {
  padding: 0 !important;
}

.mdc-dialog__title {
  padding: 0 15px 9px !important;
}

.photo-profile-wrapper {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: max-content;
}

.photo-profile-wrapper__img {
  position: relative;
  width: 85px;
  height: 85px;
  border-radius: 8px;
  cursor: pointer;
}

.photo-profile-wrapper__img .img {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
  object-position: center center;
  overflow: hidden;
}

.photo-profile-wrapper__img .btn-upload {
  position: absolute;
  bottom: -0.5rem;
  right: -0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  background: var(--secundario);
  color: var(--white);
  padding: 0;
  margin: 0;
  font-size: .85rem;
  border-radius: 50px;
  cursor: pointer;
}

.photo-profile-wrapper__info {
  width: calc(100% - 85px);
  height: max-content;
  padding-left: 1rem;
}

.photo-profile-wrapper__info .title,
.photo-profile-wrapper__info .format {
  font-size: 0.8rem;
  margin: 0;
  line-height: 1rem;
}

.mat-mdc-checkbox label {
  margin-bottom: 0;
}

.wrapper-chip-tags .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
  color: currentColor !important;
}

.mdc-evolution-chip-set .mdc-evolution-chip-set__chips {
  margin: 0 !important;
}

.wrapper-chip {
  position: relative;
}

.chip-in-campo {
  position: absolute;
  top: calc(1rem + 2px);
  left: -0.5rem;
}

.ngx-pagination a,
.ngx-pagination button {
  text-decoration: none !important;
}

.wrapper-add-button,
.wrapper-question-form {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, .25);
  color: var(--secundario);
  transition: all .15s ease-in-out;
}

.wrapper-add-button {
  cursor: pointer;
}

.wrapper-add-button:hover {
  background: rgba(0, 0, 0, .035);
}

.wrapper-add-button .icon-add {
  color: currentColor;
  margin-right: .5rem;
}

.wrapper-question-form {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: max-content;
  padding: 1rem;
  color: var(--texto);
  margin-bottom: 1rem;
}

.wrapper-question-form:last-child {
  margin-bottom: 0;
}

.wrapper-question-form .add-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 1.15rem;
  border-radius: 0.5rem;
  background: var(--secundario);
  color: var(--white);
  padding: 0;
  margin: 1rem 0 0 auto;
  cursor: pointer;
}

.wrapper-question-form .wrapper-options {
  position: relative;
  width: 100%;
  height: max-content;
  padding: 1.5rem 1rem 1rem;
  border-radius: .5rem;
  border: 1px solid rgba(0, 0, 0, .25);
  margin-bottom: 1rem;
}

.wrapper-question-form .wrapper-options .wrapper-buttons-tools {
  position: absolute;
  top: -16.5px;
  right: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: max-content;
  height: 35px;
}

.wrapper-question-form .wrapper-options .button-copy,
.wrapper-question-form .wrapper-options .button-paste {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  height: 35px;
  font-size: 1rem;
  background: var(--barra);
  color: var(--texto);
  transition: all .15s ease-in-out;
  border: 1px solid rgba(0, 0, 0, .25);
  padding: 0 .5rem;
  border-radius: 0.5rem;
  margin-left: .5rem;
  cursor: pointer;
}

.wrapper-preview-image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 22%;
  background: #e9eef1;
  border: 1px solid rgba(0, 0, 0, .25);
  border-radius: .5rem;
  margin-bottom: 1rem;
}

.wrapper-preview-image .icon-remove {
  position: absolute;
  top: .75rem;
  right: .75rem;
  font-size: 1.45rem;
  color: var(--secundario);
  padding: 0;
  cursor: pointer;
  z-index: 2;
}

.wrapper-preview-image .image {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}

.wrapper-question-form .footer-question {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 0 .5rem;
}

.wrapper-question-form .footer-question .icon-remove-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 1.15rem;
  background: var(--secundario);
  color: var(--white);
  margin-left: .75rem;
  padding: 0;
  border-radius: .5rem;
  cursor: pointer;
}

.wrapper-question-form .form-group.options-question {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.wrapper-question-form .form-group.options-question .icon-remove {
  font-size: 1.45rem;
  color: var(--secundario);
  padding: 0;
  cursor: pointer;
}

.flex {
  display: flex;
}

.flex--column {
  flex-direction: column;
}

.flex--wrap {
  flex-wrap: wrap;
}

.flex .xLeft {
  justify-content: flex-start;
}

.flex .xRight {
  justify-content: flex-end;
}

.flex .xCenter {
  justify-content: center;
}

.flex .xBetween {
  justify-content: space-between;
}

.flex .xAround {
  justify-content: space-around;
}

.flex .yLeft {
  align-items: flex-start;
}

.flex .yRight {
  align-items: flex-end;
}

.flex .yCenter {
  align-items: center;
}

[class*='az-col--'] {
  padding: 0.5rem;
}

@media only screen and (max-width: 767px) {
  [class*='az-col--'] {
    padding: 0.25rem;
  }
}

.az-col-row {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.az-col--1 {
  width: calc((100%) / 12);
}

.az-col--2 {
  width: calc((100%) / 6);
}

.az-col--3 {
  width: calc((100%) / 4);
}

.az-col--4 {
  width: calc((100%) / 3);
}

.az-col--5 {
  width: calc((100% / 12) * 5);
}

.az-col--6 {
  width: calc((100%) / 2);
}

.az-col--7 {
  width: calc((100% / 12) * 7);
}

.az-col--8 {
  width: calc((100% / 12) * 8);
}

.az-col--9 {
  width: calc((100% / 12) * 9);
}

.az-col--10 {
  width: calc((100% / 12) * 10);
}

.az-col--11 {
  width: calc((100% / 12) * 11);
}

.az-col--12 {
  width: 100%;
}

@media only screen and (max-width: 992px) {
  .az-col--table-1 {
    width: calc((100%) / 12);
  }

  .az-col--table-2 {
    width: calc((100%) / 6);
  }

  .az-col--table-3 {
    width: calc((100%) / 4);
  }

  .az-col--table-4 {
    width: calc((100%) / 3);
  }

  .az-col--table-5 {
    width: calc((100% / 12) * 5);
  }

  .az-col--table-6 {
    width: calc((100%) / 2);
  }

  .az-col--table-7 {
    width: calc((100% / 12) * 7);
  }

  .az-col--table-8 {
    width: calc((100% / 12) * 8);
  }

  .az-col--table-9 {
    width: calc((100% / 12) * 9);
  }

  .az-col--table-10 {
    width: calc((100% / 12) * 10);
  }

  .az-col--table-11 {
    width: calc((100% / 12) * 11);
  }

  .az-col--table-12 {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .az-col--mobile-1 {
    width: calc((100%) / 12);
  }

  .az-col--mobile-2 {
    width: calc((100%) / 6);
  }

  .az-col--mobile-3 {
    width: calc((100%) / 4);
  }

  .az-col--mobile-4 {
    width: calc((100%) / 3);
  }

  .az-col--mobile-5 {
    width: calc((100% / 12) * 5);
  }

  .az-col--mobile-6 {
    width: calc((100%) / 2);
  }

  .az-col--mobile-7 {
    width: calc((100% / 12) * 7);
  }

  .az-col--mobile-8 {
    width: calc((100% / 12) * 8);
  }

  .az-col--mobile-9 {
    width: calc((100% / 12) * 9);
  }

  .az-col--mobile-10 {
    width: calc((100% / 12) * 10);
  }

  .az-col--mobile-11 {
    width: calc((100% / 12) * 11);
  }

  .az-col--mobile-12 {
    width: 100%;
  }
}

.separador {
  display: block;
  width: 100%;
  height: 1px;
  margin: 0.75rem 0;
  border: none;
  border-radius: 50px;
  background: rgba(0, 0, 0, .25);
}

.form-resolver-survey .form-group .form-label {
  font-size: .95rem !important;
  font-weight: bold;
}

.form-resolver-survey .form-group .parrafo-app {
  font-size: .85rem !important;
}

.wrapper-range {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin: 1rem 0;
}

.wrapper-range .range {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: .5rem;
}

.wrapper-range .range .item {
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: 50.23%;
  text-align: center;
  background: transparent;
  border: 1px solid rgba(0, 0, 0, .35);
  border-radius: .5rem;
  user-select: none;
  font-size: 1.05rem;
  color: var(--texto);
  cursor: pointer;
  transition: all .15s ease-in-out;
}

.wrapper-range .range .item .value {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: currentColor;
  font-size: inherit;
  font-weight: inherit;
}

.wrapper-range .range .item:hover {
  background: rgba(0, 0, 0, .065);
}

.wrapper-range .range .item.selected {
  background: var(--secundario);
  color: var(--white);
  border-color: var(--secundario);
}

.wrapper-range .legend {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: max-content;
}

.wrapper-range .legend .first,
.wrapper-range .legend .last {
  width: 50%;
  max-width: 220px;
  font-size: .95rem;
  font-weight: normal;
  color: var(--texto);
  margin: .5rem 0 0;
  text-align: left;
}

.wrapper-range .legend .last {
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .wrapper-range .range {
    grid-template-columns: repeat(5, 1fr);
  }
}

.mw-td {
  min-width: 175px;
}

.bc-grey {
  border-radius: 3px;
  border: solid 1px #ddd;
}

.c-pointer {
  cursor: pointer;
  color: var(--secundario);
}

.bold {
  font-weight: bold !important;
}

.w-45 {
  width: 45% !important;
}

.width-100pc,
mat-slider.width-100pc {
  width: 90%;
}

.mat-mdc-slider.mat-primary.width-100pc {
  --mdc-slider-handle-color: #43b53f;
  --mdc-slider-focus-handle-color: #3fb557;
  --mdc-slider-hover-handle-color: #4eb53f;
  --mdc-slider-active-track-color: #57b53f;
  --mdc-slider-inactive-track-color: #50b53f;
  --mat-mdc-slider-ripple-color: #1b19193e;
}

.text-in {
  white-space: nowrap;
  overflow: hidden;
}

.NgxEditor__Content{
  min-height: 350px !important;
  max-height: 350px !important;
  overflow-y: auto !important;
}

.mdc-dialog .mat-mdc-dialog-title, .mdc-dialog .mat-mdc-dialog-content, .mdc-dialog .mat-mdc-dialog-actions {
  padding: .5rem 1rem !important;
}

.mdc-dialog .mat-mdc-dialog-title {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.col-49 {
  color: #e83926;
}

.col-50-75 {
  color: #e6cb0f;
}

.col-76 {
  color: #06993a;
}

.th-inicio .fcc {
  display: block !important;
}